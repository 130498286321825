import type {
  FlatGlobalVariables,
  FlowConfig,
  ProductConfig,
  StepConfig,
} from 'mock';
import { useEffect, useState } from 'react';

import type { HeaderStep } from '@pulse-smart-components-kit/header-base';

import { useStores } from '@shared/hooks';
import type { FormBuilderStructure, SmartComponent } from '@shared/types';

import { usePresetData } from '@features/app-config/use-preset-data';

import {
  getCommonComponents,
  getSmartComponents,
  initState,
  mergeAgentHeaderConfig,
} from './use-app-configs.helpers';

export const useAppConfigs = <T extends FlatGlobalVariables>(
  defaultConfig: ProductConfig<T>
) => {
  const {
    MainStore: {
      applicationStore: { setFlowConfig, hideStepsByName },
      productStore: { agentLogin },
      themesStore: {
        appConfig,
        presetData,
        presetValues: storePresetValues,
        setPresetValues,
      },
    },
  } = useStores();

  const [components, setComponents] = useState<FormBuilderStructure>(initState);
  const [flowConfigLocal, setFlowConfigLocal] = useState<FlowConfig<any>>({});
  const config = appConfig || defaultConfig;

  const presetValues = usePresetData(
    presetData,
    storePresetValues,
    setPresetValues,
    hideStepsByName
  );

  // Место для мёрджа конфигов: дефолтного, темы, персонализации и входных данных с маркетинговой страницы.
  useEffect(() => {
    const body: SmartComponent<any>[] = [];
    const headerConfig: HeaderStep[] = [];
    const stepConfig: StepConfig[] = [];

    config.flow.forEach((flow, flowIndex) => {
      const localStep = getSmartComponents({
        flow,
        flowIndex,
        presetValues,
      });
      body.push(...localStep.components);
      headerConfig.push(localStep.header);
      stepConfig.push(localStep.steps);
    });

    const smartComponents: FormBuilderStructure = {
      header: getCommonComponents(config.header),
      footer: getCommonComponents(config.footer),
      submit: getCommonComponents(config.submit),
      body,
    };

    setComponents(smartComponents);
    setFlowConfigLocal({
      headerConfig: mergeAgentHeaderConfig(headerConfig, agentLogin),
      stepConfig,
    });
  }, [config, presetValues]);

  useEffect(() => {
    setFlowConfig({
      ...config.globalVariables,
      maxSteps: config.flow.length,
      ...flowConfigLocal,
    });
  }, [config.globalVariables, flowConfigLocal]);

  return components;
};
