import { media, styled } from '@pulse-web-ui/theme';

export const OneBlock = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 24px;

  ${media.desktop} {
    margin-bottom: 32px;
  }
`;
export const TwoBlock = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const ThreeBlock = styled.div`
  margin: 16px 0;
  ${media.desktop} {
    margin: 24px 0;
  }
`;

export const FourBlock = styled.div``;
