import { useEffect } from 'react';

import { Endpoints, QUERY_KEYS, ErrorCode } from '@shared/constants';

import { useRequest, useStores } from '@shared/hooks';
import type { GetSubmitOrderResponse, GetSubmitOrderRequest } from './types';
import { getOrderData } from './utils';
import { hasError } from '@shared/utils';
import type { FormBuilderUpdateHandler } from '@features/form-builder';
import { sendAnalyticEvent, analyticEvents } from '@app/web-analytic';
import { useNavigate } from 'react-router-dom';
import { PublicRoutes } from '@shared/routes';

export const useSubmitOrder = (updateFormValue: FormBuilderUpdateHandler) => {
  const navigate = useNavigate();
  const {
    MainStore: {
      applicationStore: { setPaymenStep, retryPayment, setRetryPayment },
      initProductStore: { setInitOrder, initState },
      errorStore: { setErrorRetry },
      authStore: { authTokens, accessToken, setDisableForm },
      productStore: {
        formState,
        setOrderData,
        price: { promoCode, isSuccessfulPromo },
        setIsPolicyBanned,
        dadaValueStr,
        cachedDadaValueStr,
      },
    },
  } = useStores();

  const requestData: GetSubmitOrderRequest = getOrderData({
    formState,
    initState,
    promoCode: isSuccessfulPromo ? promoCode : '',
    dadaValueStr,
  });

  const { isLoading, res, error, remove, refetch } =
    useRequest<GetSubmitOrderResponse>(
      QUERY_KEYS.submitOrder,
      'post',
      Endpoints.SUBMIT_ORDER,
      requestData,
      [cachedDadaValueStr],
      true,
      accessToken
    );

  useEffect(() => {
    setDisableForm(isLoading);
    if (isLoading && !retryPayment) {
      updateFormValue('B2P', {
        initOrder: undefined,
        showInitOrder: false,
        isValid: true,
      });
      setInitOrder(undefined);
      setPaymenStep(false);
      setErrorRetry(false);
    }
  }, [isLoading]);

  useEffect(() => {
    if (!isLoading && res && accessToken) {
      setDisableForm(false);
      setOrderData(res);
      setErrorRetry(false);
      setRetryPayment(false);
      updateFormValue('DocumentsList', {
        documentsList: res.clientDocument,
        token: accessToken,
        acceptRequirements: formState?.DocumentsList?.acceptRequirements,
        isValid: formState?.DocumentsList?.isValid,
      });

      if (!error) sendAnalyticEvent(analyticEvents.flatToStepPay);
    }

    if (!isLoading && error) {
      if (hasError(error, ErrorCode.USER_DATA_ERROR)) {
        setIsPolicyBanned(true);
        navigate(PublicRoutes.POLICY_BANNED);
      }
      setDisableForm(false);
    }
  }, [isLoading, res, error, authTokens]);

  return { isLoading, res, refetch, error, remove };
};
