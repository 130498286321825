export const PlatformType = 'WEB';

export enum Endpoints {
  INIT_ORDER = '/v1/payment/init-order',
  REFRESH = '/v1/user/refresh-token',
  AUTH = '/v1/user/authorization',
  REG = '/v1/user/registration',
  AUTHENTICATION = '/v1/user/authentication',
  GET_PROFILE = '/v1/user/get-profile',
  UPDATE_PROFILE = '/v1/user/update-profile',
  COMPLETE_PAYMENT = '/v1/payment/complete-payment',
  GET_INSURANCE_PRODUCT = '/v1/references/get-insurance-product',
  CONFIRMAUTH = '/v3/user/confirm-authentication',
  SUBMIT_ORDER = '/v1/subscription/submit',
  GET_PRICES = '/v1/subscription/get-prices',
  INSURANSE_LIMIT_START = '/v3/references/insurance-limit-start',
  GET_AGE_RATION = '/v3/references/get-age-ratio',
  GET_RISKS = '/v1/references/get-risks',
  TERRITORY = '/v4/references/TERRITORY',
  GET_SPORT_ACTIVITY = '/v4/references/ACTIVITY',
  GET_SUBOBJECTS = '/v1/references/get-subobjects',
  GET_DADATA = '/v3/dadata/suggest/address',
  SCORING = '/v4/scoring/object',
  SCORING_USER = '/v4/scoring/user',
}
