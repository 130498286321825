/* eslint-disable indent */
import type { ConfigSmartComponent } from 'mock';

import type { HeaderStep } from '@pulse-smart-components-kit/header-base';

import type { BaseComponent, FormBuilderStructure } from '@shared/types';

import {
  importCommonComponents,
  importSmartComponents,
} from '@entities/import-smart-components';

import type {
  FlowStoreConfig,
  GetSmartComponentsParams,
} from './use-app-configs.types';

export const initState: FormBuilderStructure = {
  footer: [],
  body: [],
  submit: [],
  header: [],
};

export const getCommonComponents = (
  array: ConfigSmartComponent[]
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): BaseComponent<any>[] =>
  array
    .filter((component) => !!importCommonComponents[component.name])
    .map((component) => {
      const { value, ...options } = component?.additionalVariables || {};

      return {
        component: importCommonComponents[component.name],
        props: {
          name: component.name,
          id: component.id,
          value: {
            ...value,
          },
          options,
        },
      };
    });

export const getSmartComponents = ({
  flow: { components, name, icon, additionalVariables },
  flowIndex,
  presetValues,
}: GetSmartComponentsParams): FlowStoreConfig => {
  return {
    steps: {
      step: flowIndex + 1,
      value: { ...additionalVariables },
    },
    header: {
      icon,
      name,
    },
    components: components
      .filter((component) => !!importSmartComponents[component.name])
      .map((component) => {
        const { value, ...options } = component?.additionalVariables || {};

        return {
          component: importSmartComponents[component.name],
          step: flowIndex + 1,
          props: {
            name: component.name,
            id: component.id,
            value: {
              ...value,
              ...(presetValues ? presetValues[component.name] : {}),
            },
            options,
          },
        };
      }),
  };
};

//TODO: Продуктовая история, вынести наружу, сделать метод для использования в компоненте
export const mergeAgentHeaderConfig = (
  headerConfig: HeaderStep[],
  agentLogin?: string
) => {
  if (!agentLogin) return headerConfig;

  const agentPaymentStep: HeaderStep = {
    name: 'formalization',
    icon: 'DocumentFull',
  };

  const agentClientStep: HeaderStep = {
    name: 'clients',
    icon: 'Neighbors',
  };

  return headerConfig.map((step) => {
    switch (step.name) {
      case 'payment':
        return agentPaymentStep;
      case 'formalization':
        return agentClientStep;
      default:
        return step;
    }
  });
};
