import { useCallback, useMemo } from 'react';

import { PresetResponse } from '@shared/types';

import { useHandleGetRisks } from '@entities/adapters/insurance-risks-adapter/hooks';
import type { HiddenSteps } from '@entities/stores/application-store';
import type { PresetValues } from '@entities/stores/themes-store';

export const usePresetData = (
  presetData?: PresetResponse,
  storePresetValues?: PresetValues,
  setPresetValues: (e: PresetValues) => void = () => {},
  hideStepsByName: (steps: HiddenSteps[]) => void = () => {}
) => {
  const risksResponse = useHandleGetRisks();

  const parsePresetData = useCallback(
    (presetResponseData: PresetResponse) => {
      hideStepsByName([{ calculation: 2 }]);

      const risksDictionary = risksResponse.data || [];
      const presetRisksMap = (presetResponseData.risks || [])?.reduce(
        (acc, item) => {
          return { ...acc, [item]: true };
        },
        {} as Record<string, boolean>
      );

      return {
        InsuranceRisks: {
          risks: risksDictionary.map((risk) => ({
            ...risk,
            active: Boolean(presetRisksMap[risk.code]),
          })),
          isValid: true,
        },
        InsurancePeriodAndLimits: {
          limits: presetResponseData.subObjects.reduce(
            (acc, curr) => ({
              ...acc,
              [curr.code]: Number(curr.defaultInsuranceSum),
            }),
            {}
          ),
          isValid: true,
        },
      };
    },
    [hideStepsByName, risksResponse.data]
  );

  const presetValues = useMemo(() => {
    if (!risksResponse.data) {
      return {};
    }

    if (storePresetValues) {
      return storePresetValues;
    }

    const values = presetData ? parsePresetData(presetData) : undefined;

    if (!storePresetValues && values) {
      setPresetValues(values);
    }

    return values || {};
  }, [parsePresetData, presetData, storePresetValues, risksResponse.data]);

  return presetValues;
};
