import type { GetSubmitOrderRequest } from '../types';
import { INSURANSE_SUM_DEFAULT } from '../constants';
import { prepareDate, getAddress } from '@shared/utils';
import type { InsuranceConvertedSubProduct } from '@shared/types';
import type { FormStateType, InitStateType } from '@entities/stores';

const today = new Date();
const tomorrow = new Date(today);
tomorrow.setDate(tomorrow.getDate() + 1);

const birthDate = new Date(today);
birthDate.setFullYear(tomorrow.getFullYear() - 30);

export const getOrderData = ({
  formState,
  initState,
  promoCode,
  dadaValueStr,
}: {
  dadaValueStr?: string,
  formState: FormStateType;
  initState: InitStateType;
  promoCode: string;
}) => {
  const dateBegin = new Date(formState?.StartDate?.date || new Date());

  const risks = formState?.InsuranceRisks?.risks?.map((risk) => risk.code);
  const limits = formState?.InsurancePeriodAndLimits?.limits;

  const subObjects: InsuranceConvertedSubProduct[] = [];

  const totalInsuranceSum =
    Object.entries(limits || {}).reduce((sum, [code, insuranceSum]) => {
      if (insuranceSum !== 0) {
        subObjects.push({
          code,
          insuranceSum: insuranceSum.toString(),
        });
      }
      return sum + insuranceSum;
    }, 0) || INSURANSE_SUM_DEFAULT;

  const addressPrepared = dadaValueStr
    ? getAddress(formState?.InsuranceAddress, dadaValueStr)
    : undefined;

  const risksPrepared = risks ? [...risks] : [];

  if (subObjects?.find((obj) => obj.code === 'Liability_1')) {
    risksPrepared.push('Liability_1');
  }

  const data: GetSubmitOrderRequest = {
    effectiveSince: prepareDate(dateBegin),
    productCode: initState.code || '',
    contractDuration: formState?.InsurancePeriodAndLimits?.period || 'P3M',
    promoCode,
    insuranceSum: totalInsuranceSum.toString(),

    // TODO remove hardcode
    risks: risksPrepared,
    personProperties: {
      subObjects,

      // TODO remove mock
      address: addressPrepared ?? {
        address: '',
        addressCode: '',
        regionCode: '',
        placeCode: '',
        cityCode: '',
      },
    },
  };

  return data;
};
