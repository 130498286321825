// import { useAxiosInterceptors } from '@shared/hooks';
import { Suspense, lazy } from 'react';
import { Route, Routes } from 'react-router-dom';

import { Spinner } from '@pulse-web-ui/spinner';

import { FallbackSkeleton } from '@shared/components';
import { PublicRoutes } from '@shared/routes';

import { CheckUserData } from './check-user-data';
import { InProgressPay } from './in-progress-pay';

const Main = lazy(() => import('./main'));
const Description = lazy(() => import('./description'));
const NotFound = lazy(() => import('./not-found'));
const Login = lazy(() => import('./login'));
const CornerCase = lazy(() => import('./corner-case'));

// Pay
const PaymentSuccessLoader = lazy(() => import('./payment-success-loader'));
const PaymentInProgressLoader = lazy(
  () => import('./payment-in-progress-loader')
);
const PaymentErrorLoader = lazy(() => import('./payment-error-loader'));

const SuccessPay = lazy(() => import('./success-pay'));
const FailedPay = lazy(() => import('./failed-pay'));
const PolicyBanned = lazy(() => import('./policy-banned'));
const VipClient = lazy(() => import('./vip-client'));
const AuthorizeFail = lazy(() => import('./authorize-fail'));

const AddressError = lazy(() => import('./address-error'));

const Routing = () => {
  return (
    <Suspense
      fallback={
        <FallbackSkeleton height={800} transparent fullWidth>
          <Spinner />
        </FallbackSkeleton>
      }
    >
      <Routes>
        <Route
          key={PublicRoutes.ROOT}
          path={PublicRoutes.ROOT}
          element={<Main />}
        />
        {/* Роут заглушка для теста логики пресет + корнер кейс
        Удалить после реализации роута для коренер кейса */}
        <Route
          key={PublicRoutes.CORNER_CASE}
          path={PublicRoutes.CORNER_CASE}
          element={<CornerCase />}
        />
        <Route
          key={PublicRoutes.DESCRIPTION}
          path={PublicRoutes.DESCRIPTION}
          element={<Description />}
        />
        <Route
          key={PublicRoutes.POLICY_BANNED}
          path={PublicRoutes.POLICY_BANNED}
          element={<PolicyBanned />}
        />
        <Route
          key={PublicRoutes.AUTHORIZE_FAIL}
          path={PublicRoutes.AUTHORIZE_FAIL}
          element={<AuthorizeFail />}
        />

        {/* Payments */}
        <Route
          key={PublicRoutes.SUCCESS_PAY}
          path={PublicRoutes.SUCCESS_PAY}
          element={<SuccessPay />}
        />
        <Route
          key={PublicRoutes.IN_PROGRESS_PAY}
          path={PublicRoutes.IN_PROGRESS_PAY}
          element={<InProgressPay />}
        />
        <Route
          key={PublicRoutes.FAILED_PAY}
          path={PublicRoutes.FAILED_PAY}
          element={<FailedPay />}
        />

        {/* Payments B2P */}
        <Route
          key={PublicRoutes.PAYMENT_SUCCESS_ROUTE}
          path={PublicRoutes.PAYMENT_SUCCESS_ROUTE}
          element={<PaymentSuccessLoader />}
        />
        <Route
          key={PublicRoutes.PAYMENT_ERROR_ROUTE}
          path={PublicRoutes.PAYMENT_ERROR_ROUTE}
          element={<PaymentErrorLoader />}
        />
        <Route
          key={PublicRoutes.PAYMENT_IN_PROGRESS_ROUTE}
          path={PublicRoutes.PAYMENT_IN_PROGRESS_ROUTE}
          element={<PaymentInProgressLoader />}
        />

        {/* Payments B2P */}
        <Route
          key={PublicRoutes.LOGIN}
          path={PublicRoutes.LOGIN}
          element={<Login />}
        />
        <Route
          key={PublicRoutes.NOT_FOUND}
          path={PublicRoutes.NOT_FOUND}
          element={<NotFound />}
        />
        <Route
          key={PublicRoutes.VIP_CLIENT}
          path={PublicRoutes.VIP_CLIENT}
          element={<VipClient />}
        />
        <Route
          key={PublicRoutes.ADDRESS_ERROR}
          path={PublicRoutes.ADDRESS_ERROR}
          element={<AddressError />}
        />
        <Route
          path={PublicRoutes.CHECK_USER_DATA}
          element={<CheckUserData />}
        />
      </Routes>
    </Suspense>
  );
};

export default Routing;
