import { action, makeAutoObservable, observable, runInAction } from 'mobx';
import { FieldValues } from 'react-hook-form';
import { AgeSelectItem } from 'smart-components';

import type { InsuranceRisksValues } from '@pulse-smart-components-kit/insurance-risks';
import type { InsuredAmountConfigurationValues } from '@pulse-smart-components-kit/insured-amount-configuration';

import { Main } from '@app/store';

import { RegionType } from '@shared/types';
import type {
  AgeRatio,
  Country,
  ProductPrice,
  StoreTraveler,
  Traveler,
} from '@shared/types';

import {
  getAgeFormStoreTraveler,
  getAgeRange,
  getCurrency,
  getTravelerFormStoreTraveler,
  updateTravelersAges,
  updateTravelersData,
} from './utils';

const ageRatios: AgeRatio[] = [
  {
    ageMin: 1,
    ageMax: 90,
    ageCategory: 'Взрослый',
  },
];

export const initPrice: ProductPrice = {
  premiumAndDelta: '',
  promoCode: '',
};

export type FormStateType = {
  InsuranceAddress?: FieldValues;
  WhoAndHow?: FieldValues;
  WhoIssuesPolicy?: FieldValues;
  WantImprovePolicy?: FieldValues;
  WhereAndHowLong?: FieldValues;
  WhoAndHowPaperwork?: FieldValues;
  DocumentsList?: FieldValues;
  StartDate?: FieldValues;
  InsuranceRisks?: InsuranceRisksValues;
  InsurancePeriodAndLimits?: InsuredAmountConfigurationValues;
};

type OrderDataType = {
  billId: string;
  clientDocument: {
    clientDocument: string;
    clientDocumentType: 'Rules' | 'Memo' | 'Contract' | 'KeyInfoDocument';
  }[];
  contractId: string;
  contractNumber: string;
  orderNumber: string;
  premiumAndDelta: string;
  subscriptionId: string;
  subscriptionNumber: string;
  subscriptionType: string;
};

export class ProductStore {
  private mainStore: Main;
  pulseClient?: boolean; // Связь с чекбоксом “Я уже ваш клиент” на авторизации
  ageRatios: AgeRatio[] = ageRatios; // Для валидации, когда мы выбираем возраст путешественника
  fullRisksOptionsLength: number = 0; // Длина рисков и опций приходящих с бекенда, фильтруется на фронте
  formState: FormStateType = {}; // Форма со смарт компонентами и их базовыми свойствами
  formString: string = ''; // Форма смарт компонентов в виде строки, используется в bff
  price: ProductPrice = initPrice; // Цена в футере
  orderData?: OrderDataType = undefined; // Данные полученные с post запроса submit-order
  hasPricesCalcErr: boolean = false; // Ошибка в цене, отрисовка в cостояние error, если сумма превышает досигаемой.
  isPolicyBanned: boolean = false; // Признак ошибки о невозможности оформления полиса.
  agentLogin?: string;
  travelers: StoreTraveler[] = []; // Единый список путешественников для синхронизации между смарт компонентами.
  cachedDadaValueStr?: string;
  dadaValueStr?: string;
  selectedRegion?: RegionType;

  constructor(mainStore: Main) {
    this.mainStore = mainStore;
    makeAutoObservable(this, {
      pulseClient: observable,
      setPulseClient: action,
      ageRatios: observable,
      setAgeRatios: action,
      fullRisksOptionsLength: observable,
      setFullRisksOptionsLength: action,
      formState: observable,
      setFormState: action,
      setOrderData: action,
      formString: observable,
      price: observable,
      cachedDadaValueStr: observable,
      dadaValueStr: observable,
      setPrice: action,
      setHasPricesCalcErr: action,
      agentLogin: observable,
      isPolicyBanned: observable,
      selectedRegion: observable,
      setAgentLogin: action,
      clearPromoCode: action,
      setIsPolicyBanned: action,
      travelers: observable,
      setTravelersAges: action,
      setTravelersData: action,
      setCachedDadaValueStr: action,
      setDadaValueStr: action,
      setSelectedRegion: action,
    });
  }

  get countries(): Country[] | undefined {
    return this.formState?.WhereAndHowLong?.countries;
  }

  get insuranceSumm(): string {
    return this.formState.WhoAndHow?.coverageSum;
  }

  get travelDates(): { start: Date | undefined; end: Date | undefined } {
    return this.formState.WhereAndHowLong?.travelDates;
  }

  get ageRange() {
    return getAgeRange(this.ageRatios);
  }

  get currency() {
    return getCurrency(this);
  }

  setPrice = (e: Partial<ProductPrice>) => {
    this.price = { ...this.price, ...e };
  };

  setCachedDadaValueStr = (e: string | undefined) => {
    this.cachedDadaValueStr = e;
  };

  setDadaValueStr = (e: string | undefined) => {
    this.dadaValueStr = e;
  };

  setSelectedRegion = (e: RegionType | undefined) => {
    this.selectedRegion = e;
  };

  clearPromoCode = () => {
    this.price = {
      ...this.price,
      isSuccessfulPromo: undefined,
      premiumAndDeltaPromo: undefined,
    };
  };

  setHasPricesCalcErr = (e: boolean) => {
    this.hasPricesCalcErr = e;
  };

  setAgeRatios = (e: AgeRatio[]) => {
    this.ageRatios = e;
  };

  setOrderData = (e: OrderDataType) => {
    this.orderData = e;
  };

  setPulseClient = (e: boolean) => {
    this.pulseClient = e;
  };

  setFormState = (e: Partial<FormStateType>) => {
    runInAction(() => {
      this.formState = {
        ...e,
      };
      this.formString = JSON.stringify(e);
    });
  };

  setFullRisksOptionsLength = (e: number) => {
    this.fullRisksOptionsLength = e;
  };

  setIsPolicyBanned = (e: boolean) => {
    this.isPolicyBanned = e;
    runInAction(() => {
      this.mainStore.applicationStore.setIsHeaderShowing(!e);
      this.mainStore.applicationStore.setIsFooterShowing(!e);
      this.mainStore.applicationStore.setIsSubmitShowing(!e);
      this.mainStore.applicationStore.setIsSmartComponentsShowing(!e);
    });
  };

  setAgentLogin = (e: string) => {
    this.agentLogin = e;
  };

  setTravelersAges = (
    e: AgeSelectItem[],
    onClearMyselfAge: VoidFn
  ): Traveler[] => {
    this.travelers = updateTravelersAges(this.travelers, e, onClearMyselfAge);

    return this.travelers.map(getTravelerFormStoreTraveler);
  };

  setTravelersData = (e: Traveler[]): AgeSelectItem[] => {
    if (!this.mainStore.authStore.hasAuthorized)
      return this.travelers.map(getAgeFormStoreTraveler);

    this.travelers = updateTravelersData(e);

    return this.travelers.map(getAgeFormStoreTraveler);
  };
}
