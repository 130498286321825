import { DEFAULT_APP_CONFIG } from 'mock';
import React, { memo } from 'react';

import { FormBuilder } from '@features/form-builder';

import { useAppConfigs } from './use-app-configs';

export const AppConfig = memo(() => {
  const components = useAppConfigs(DEFAULT_APP_CONFIG);

  return <FormBuilder components={components} />;
});

AppConfig.displayName = 'AppConfig';
