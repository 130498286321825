import { observer } from 'mobx-react-lite';
import { FC, type Ref, Suspense, forwardRef, useEffect, useMemo } from 'react';
import type { SmartComponentAdapterProps } from 'smart-components';

import type {
  StartDateOptions,
  StartDateValues,
} from '@pulse-smart-components-kit/start-date';
import { StartDateAnalyticEvent } from '@pulse-smart-components-kit/start-date/lib/constants';

import {
  analyticEvents, // analyticEvents,
  useHandleSmartComponentsAnalyticEvents,
} from '@app/web-analytic';

import { useStores } from '@shared/hooks';
import type { AnalyticEventsMap } from '@shared/types';

import { SmartStartDate } from '@entities/import-smart-components/smart-start-date';

import { formStartDateSchema } from './schema';
import { StartDateSkeleton } from './skeleton';

const analyticEventsMap: AnalyticEventsMap<StartDateAnalyticEvent> = {
  [StartDateAnalyticEvent.ON_DATE_SELECT]: {
    name: analyticEvents.flatStartDateSelected,
  },
};

export const StartDateAdapter: FC<SmartComponentAdapterProps<StartDateValues>> =
  observer(
    forwardRef(
      (
        { value, onChange, isSubmitting, fieldState },
        forwardRef: Ref<HTMLDivElement>
      ) => {
        const {
          MainStore: {
            initProductStore: {
              insuranceLimitStart: { startDate, endDate },
            },
            authStore: { disableForm, hasAuthorized },
          },
        } = useStores();

        const options: StartDateOptions = useMemo(
          () => ({
            minDate: new Date(startDate),
            maxDate: new Date(endDate),
            isControlsHidden: true,
          }),
          []
        );

        const handleAnalyticEventSend =
          useHandleSmartComponentsAnalyticEvents<StartDateAnalyticEvent>(
            analyticEventsMap
          );

        useEffect(() => {
          if (startDate) {
            onChange({ date: new Date(startDate) });
          }
        }, []);

        if (!hasAuthorized) {
          return null;
        }

        return (
          <Suspense fallback={<StartDateSkeleton />}>
            <SmartStartDate
              ref={forwardRef}
              value={value}
              onChange={onChange}
              isSubmitting={isSubmitting}
              options={options}
              disabled={disableForm}
              fieldState={fieldState}
              formSchema={formStartDateSchema}
              onAnalyticEventSend={handleAnalyticEventSend}
            />
          </Suspense>
        );
      }
    )
  );

StartDateAdapter.displayName = 'StartDateAdapter';
