import { observer } from 'mobx-react-lite';
import { Suspense, forwardRef, useMemo } from 'react';
import type { Ref } from 'react';
import { useTranslation } from 'react-i18next';
import type { SmartComponentAdapterProps } from 'smart-components';

import type { InsuredAmountConfigurationValues } from '@pulse-smart-components-kit/insured-amount-configuration';

import { useStores, useWindowSize } from '@shared/hooks';

import { SmartInsuranceAmountConfiguration } from '@entities/import-smart-components/smart-insurance-amount-configuration';

import { StyledWrapper } from './insurance-period-and-limits-adapter.style';
import { useHandleGetSubobjects } from './queries';
import { InsurancePeriodAndLimitsSkeleton } from './skeleton';
import { prepareConfigurationOptions } from './utils';
import { preparePrices } from './utils/prepare-prices';

export const InsurancePeriodAndLimitsAdapter = observer(
  forwardRef<
    HTMLDivElement,
    SmartComponentAdapterProps<InsuredAmountConfigurationValues>
  >(({ isSubmitting, onChange, value }, forwardRef: Ref<HTMLDivElement>) => {
    const {
      MainStore: {
        authStore: { disableForm },
        productStore: { price },
      },
    } = useStores();
    const { t } = useTranslation();

    const { data, isLoading } = useHandleGetSubobjects();

    const [windowWidth] = useWindowSize();

    const pricesOptions = useMemo(() => {
      return preparePrices(windowWidth, price.prices);
    }, [windowWidth, price.prices]);

    const options = useMemo(
      () => ({
        options: prepareConfigurationOptions(data, value),
        subTitle: t('INSURANCE_PERIOD_AND_LIMITS:label.subTitle'),
        title: t('INSURANCE_PERIOD_AND_LIMITS:label.title'),
        defaultPeriod: value?.period ?? price.defaultContractDuration,
        periodOptions: pricesOptions ?? [],
      }),
      [data, value, value?.period, price.defaultContractDuration, pricesOptions]
    );

    if (isLoading || !data || !pricesOptions?.length) {
      return <InsurancePeriodAndLimitsSkeleton />;
    }

    return (
      <Suspense fallback={<InsurancePeriodAndLimitsSkeleton />}>
        <StyledWrapper>
          <SmartInsuranceAmountConfiguration
            disabled={disableForm}
            isSubmitting={isSubmitting}
            onChange={onChange}
            ref={forwardRef}
            options={options}
          />
        </StyledWrapper>
      </Suspense>
    );
  })
);

InsurancePeriodAndLimitsAdapter.displayName = 'InsurancePeriodAndLimitsAdapter';
