import { useEffect, useMemo } from 'react';

import { Endpoints } from '@shared/constants';
import { useRequest, useStores } from '@shared/hooks';
import type { InsuranceLimitStart as InsuranceLimitStartResponse } from '@shared/types/insurance-limit-start.types';
import { parseProductCode } from '@shared/utils';

export const useInsuranceLimitStart = () => {
  const {
    MainStore: {
      initProductStore: { initState, setInsuranceLimitStart },
    },
  } = useStores();

  const { code, maxProductLimit } = initState;

  const { productCode, productVersion } = useMemo(
    () => parseProductCode(code),
    [code]
  );

  const {
    res: resInsuranceLimitStart,
    refetch: refetchInsuranceLimit,
    isLoading: isLoadingInsuranceLimitStart,
  } = useRequest<InsuranceLimitStartResponse>(
    'get-sport-activity',
    'get',
    `${Endpoints.INSURANSE_LIMIT_START}/${productCode}/${productVersion}`,
    {},
    [],
    true
  );

  useEffect(() => {
    if (maxProductLimit) {
      refetchInsuranceLimit();
    }
  }, [maxProductLimit]);

  useEffect(() => {
    if (resInsuranceLimitStart && !isLoadingInsuranceLimitStart) {
      setInsuranceLimitStart(resInsuranceLimitStart);
    }
  }, [resInsuranceLimitStart, isLoadingInsuranceLimitStart]);

  return {
    isLoadingInsuranceLimitStart,
    resInsuranceLimitStart,
    refetchInsuranceLimit,
  };
};
