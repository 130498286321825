import { Skeleton } from '@pulse-web-ui/skeleton';
import { Container, Wrapper } from './skeleton.styles';

export const InsuranceAddressSkeleton = () => {
  return (
    <Container>
      <Skeleton
        width={195}
        widthMobile={163}
        height={32}
        heightMobile={28}
        borderRadius={12}
      />
      <Wrapper>
        {Array.from({ length: 5 }).map((_, idx) => (
          <Skeleton key={idx} borderRadius={12} />
        ))}
      </Wrapper>
    </Container>
  );
};
