import React from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from '@pulse-web-ui/modal';
import { formattingPrice } from '@pulse-web-platform-core/utils';
import { Risk } from '@pulse-smart-components-kit/insurance-risks';
import { HeaderWithSubText } from '@pulse-web-ui/header-with-sub-text';
import { footerPeriodNames } from '@shared/constants';
import type { SelectedDuration } from '@shared/types';

import {
  ButtonsWrapper,
  SubObjectName,
  SubObjectPrice,
  SubscriptionDataContent,
  SubscriptionDataContentItem,
  SubscriptionDataRiskText,
  SubscriptionDataWrapper,
  SubscriptionOldPrice,
  SubscriptionPrice,
  SubTitle,
  TotalSum,
  AdaptiveButton,
  SubscriptionPriceWrapper,
} from './available-subscription.styles';

import type { InsuranceSubObject } from '../../check-user-data.types';

interface AvailableSubscriptionProps {
  risks?: Risk[];
  period: SelectedDuration;
  insuranceSum?: number;
  insuranceSumPerMonth: number;
  subobjects?: InsuranceSubObject[];
  insurancePromoSumPerMonth: number;
}

export const AvailableSubscription = ({
  risks,
  period,
  subobjects,
  insuranceSum,
  insuranceSumPerMonth,
  insurancePromoSumPerMonth,
}: AvailableSubscriptionProps) => {
  const { t } = useTranslation();

  const priceToFormat = Math.ceil(
    insurancePromoSumPerMonth || insuranceSumPerMonth
  );

  return (
    <Modal
      showDialog={true}
      closeIcon={false}
      desktopWidth={728}
      dangerouslyBypassScrollLock
    >
      <div>
        <HeaderWithSubText title={t('COMMON:headers.availableSubscription')} />
        <SubscriptionDataWrapper>
          <SubTitle>{t('COMMON:hints.totalSumInsured')}</SubTitle>
          {insuranceSum && <TotalSum>{formattingPrice(insuranceSum)}</TotalSum>}
          {subobjects && (
            <SubscriptionDataContent>
              {subobjects.map((subObject) => (
                <SubscriptionDataContentItem key={subObject.name}>
                  <SubObjectName>{subObject.name}</SubObjectName>
                  <SubObjectPrice>
                    {`${t('COMMON:labels.to')} ${formattingPrice(+subObject.defaultInsuranceSum)}`}
                  </SubObjectPrice>
                </SubscriptionDataContentItem>
              ))}
            </SubscriptionDataContent>
          )}
        </SubscriptionDataWrapper>
        <SubscriptionDataWrapper>
          <SubTitle>{t('COMMON:hints.insuranceRisks')}</SubTitle>
          <SubscriptionDataContent>
            {risks
              ?.filter(({ active }) => active)
              ?.map((risk) => (
                <ul key={risk.name}>
                  <SubscriptionDataRiskText>
                    {risk.name}
                  </SubscriptionDataRiskText>
                </ul>
              ))}
          </SubscriptionDataContent>
        </SubscriptionDataWrapper>
        <SubscriptionPriceWrapper>
          <SubTitle>{t('COMMON:hints.subscriptionPrice')}</SubTitle>
          <div>
            <SubscriptionPrice>{`${formattingPrice(priceToFormat)} ${footerPeriodNames[period]}`}</SubscriptionPrice>
            {!!insurancePromoSumPerMonth && (
              <SubscriptionOldPrice>{`${formattingPrice(Math.ceil(insuranceSumPerMonth))}`}</SubscriptionOldPrice>
            )}
          </div>
        </SubscriptionPriceWrapper>
        <ButtonsWrapper>
          <AdaptiveButton>{t('COMMON:labels.accept')}</AdaptiveButton>
          <AdaptiveButton variant="secondary-2">
            {t('COMMON:labels.edit')}
          </AdaptiveButton>
        </ButtonsWrapper>
      </div>
    </Modal>
  );
};
