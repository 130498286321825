import { makeAutoObservable } from 'mobx';
import { IFLFlatProductConfig } from 'mock';
import { FieldValues } from 'react-hook-form';

import { Main } from '@app/store';

import type { PresetResponse } from '@shared/types';

export type PresetValues = Record<string, FieldValues>;

export class ThemesStore {
  private mainStore: Main; // позволяет обращаться в другие сторы
  themeUrl?: string;
  appConfig?: IFLFlatProductConfig;
  presetData?: PresetResponse; // сырые данные из JSON пресета
  presetValues?: PresetValues; // данные после парсинга и нормализации JSON пресета

  constructor(mainStore: Main) {
    this.mainStore = mainStore;
    makeAutoObservable(this);
  }

  setThemeUrl = (e: string) => {
    this.themeUrl = e;
  };

  setAppConfig = (e: IFLFlatProductConfig) => {
    this.appConfig = e;
  };

  setPresetData = (e?: PresetResponse) => {
    this.presetData = e;
  };

  setPresetValues = (e?: PresetValues) => {
    this.presetValues = e;
  };
}
