import * as yup from 'yup';
import i18n from '@app/i18n/config';

import type { DaDataAddressSuggestion } from '@pulse-web-ui/dadata';

export const getSmartAddressSchema = (dadaValue?: DaDataAddressSuggestion) =>
  yup.object().shape({
    region: yup
      .string()
      .required(i18n.t('SMART:InsuranceAddress.errors.specifyRegion')),
    city: yup
      .string()
      .required(i18n.t('SMART:InsuranceAddress.errors.specifyLocality'))
      .test(
        'isSelectedCity',
        'COMMON:hints.noSuitableAddress',
        () => !!(dadaValue?.data?.city || dadaValue?.data?.settlement)
      ),
    house: yup
      .string()
      .required(i18n.t('SMART:InsuranceAddress.errors.specifyHouse')),
    isValid: yup.bool(),
  });
