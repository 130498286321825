export const analyticEvents = {
  webStart: 'Purchase_Web_Start',
  travelStart: 'Purchase_Travel_Start',
  travelCountrySelected: 'Purchase_Travel_Country_Selected',
  travelCantSell: 'Purchase_Travel_CantSell',
  travelDatesSelected: 'Purchase_Travel_Dates_Selected',
  travelTravellersSelected: 'Purchase_Travel_Travellers_Selected',
  travelSumSelected: 'Purchase_Travel_Sum_Selected',
  travelSport: 'Purchase_Travel_Sport',
  travelSummary: 'Purchase_Travel_Summary',
  travelSportSelected: 'Purchase_Travel_Sport_Selected',
  travelViewOptionsFooter: 'Purchase_Travel_ViewOptions_Footer',
  travelViewOptionsOptions: 'Purchase_Travel_ViewOptions_Options',
  travelOptionAdded: 'Purchase_Travel_Option_Added',
  travelToStepPay: 'Purchase_Travel_To_Step_Pay',
  travelerTravellerDataEntered: 'Purchase_Travel_Traveller_Data_Entered',
  travelerTravellerAdded: 'Purchase_Travel_Traveller_Added',
  travelerTravellerDeleted: 'Purchase_Travel_Traveller_Deleted',
  travelRegistrationStart: 'Purchase_Travel_Registration_Start',
  travelerEnterCodeSuccess: 'Purchase_Travel_EnterCode_Success',
  travelRegistrationSuccess: 'Purchase_Travel_Registration_Success',
  travelPaymentSuccess: 'Purchase_Travel_Payment_Success',
  travelPaymentDecline: 'Purchase_Travel_Payment_Decline',
  // TODO убрать старые события после замены на события квартиры
  flatConfigure: 'Flat_Configure',
  flatAddressEntered: 'Flat_Address_Entered',
  flatChangePeriod: 'Flat_Change_Period',
  flatChangeLimit: 'Flat_Change', // Flat_Change_[код лимита]
  flatHowToSummLimit: 'Flat_HowToSumm', // Flat_HowToSumm_[код лимита]
  flatRiskDesc: 'Flat_Risk_Desc',
  flatChangeRisk: 'Flat_Change_Risk',
  flatToStepData: 'Flat_To_Step_Data',
  flatSignUpStart: 'Flat_Sign_Up_Start',
  flatInsurerDataEntered: 'Flat_Insurer_Data_Entered',
  flatEmailEntered: 'Flat_Email_Entered',
  flatPhoneEntered: 'Flat_Phone_Entered',
  flatSignUpSuccess: 'Flat_Sign_Up_Success',
  flatStartDateSelected: 'Flat_StartDate_Selected',
  flatPromoAppliedSuccess: 'Flat_Promo_Applied_Success',
  flatPromoAppliedFailed: 'Flat_Promo_Applied_Failed',
  flatToStepPay: 'Flat_To_Step_Pay',
  flatOptionsOpened: 'Flat_Options_Opened',
  flatKIDOpened: 'Flat_KID_Opened',
  flatPolisOpened: 'Flat_Polis_Opened',
  flatRulesOpened: 'Flat_Rules_Opened',
  flatMemoOpened: 'Flat_Memo_Opened',
  flatOpenPayForm: 'Flat_Open_Pay_Form',
  flatPaymentSuccess: 'Flat_Payment_Success',
  flatPaymentDecline: 'Flat_Payment_Decline',
  flatDownloadApp: 'Flat_Download_App',
  flatSubscribeTG: 'Flat_Subscribe_TG',
  flatSubscribeVK: 'Flat_Subscribe_VK',
  flatToStepCalculate: 'Flat_To_Step_Calculate',
};
