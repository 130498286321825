export { PlatformType, Endpoints } from './request-data';
export { TIME_ZONE_MOSCOW, YEAR_MS } from './time';
export { Currency, CurrencyLabel } from './currency';
export { EXCLUDE_OPTIONS } from './excluded-options';
export { RISK_VALUE } from './risks';
export { INSURANCE_SUM_DEFAULT } from './initial-coverge-sum';
export { CODE_DESCRIPTION } from './code-description';
export { PRICES_MAX_VALUE } from './prices-max-value';
export { PRODUCT_CODE } from './product-code';
export {
  phoneRegEx,
  petNameRegEx,
  promoEx,
  onlyLetters,
  maxLength,
  onlyCyrillic,
  addressRegEx,
  emailRegEx,
  onlyNumbers,
  ageRegEx,
} from './reg-ex';
export { REGISTRATION, REGISTRATION_ERROR_TEXT } from './registration';
export { QUERY_KEYS, DOCUMENT_QUERY_KEY } from './query-keys';
export { UnicodeEnum } from './unicodes';
export { IFrameMessage } from './iframe-message';
export { POST_MESSAGE_TYPE } from './post-message-type';
export { Product } from './product';
export { adaptivePeriodNames, periodNames, footerPeriodNames } from './period';
export { ObjectType } from './object-type';
export { ErrorCode } from './error-code';

export const DEFAULT_FIAS_ID = '00000000-0000-0000-0000-000000000000';
export const EMPTY_REGION = [
  {
    region: '',
    regionName: '',
    regionTariff: undefined,
    version: '',
  },
];

export enum UseQueryStatus {
  ERROR = 'error',
  SUCCESS = 'success',
  IDLE = 'idle',
  LOADING = 'loading',
}
