import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { useStores } from '@shared/hooks';

interface FormNavigateOptions {
  navigateToForm?: boolean;
  ignoreHiddenSteps?: boolean;
}

export const useFormNavigation = () => {
  const {
    MainStore: {
      applicationStore: { showHiddenSteps, setActiveStep },
    },
  } = useStores();
  const navigate = useNavigate();

  return useCallback(
    (stepNumber: number, options?: FormNavigateOptions) => {
      if (options?.ignoreHiddenSteps) {
        showHiddenSteps();
      }

      setActiveStep(stepNumber);

      if (options?.navigateToForm) {
        navigate('/');
      }
    },
    [showHiddenSteps, navigate]
  );
};
