import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { analyticEvents, sendAnalyticEvent } from '@app/web-analytic';

import { Endpoints, ErrorCode, QUERY_KEYS } from '@shared/constants';
import { useRequest, useStores } from '@shared/hooks';
import { PublicRoutes } from '@shared/routes';
import { hasError } from '@shared/utils';

import type { FormBuilderUpdateHandler } from '@features/form-builder';

import type { GetSubmitOrderRequest, GetSubmitOrderResponse } from './types';
import { getOrderData } from './utils';

export const useSubmitOrder = (updateFormValue: FormBuilderUpdateHandler) => {
  const navigate = useNavigate();
  const {
    MainStore: {
      applicationStore: { setPaymenStep, retryPayment, setRetryPayment },
      initProductStore: { setInitOrder, initState },
      errorStore: { setErrorRetry },
      authStore: { authTokens, accessToken, setDisableForm },
      themesStore: { presetValues },
      productStore: {
        price,
        formState,
        setOrderData,
        price: { promoCode, isSuccessfulPromo },
        setIsPolicyBanned,
        dadaValueStr,
        cachedDadaValueStr,
      },
    },
  } = useStores();

  const requestData: GetSubmitOrderRequest = getOrderData({
    formState,
    initState,
    promoCode: isSuccessfulPromo ? promoCode : '',
    dadaValueStr,
    defaultContractDuration:
      presetValues?.InsurancePeriodAndLimits?.defaultContractDuration ??
      price?.defaultContractDuration,
  });

  const { isLoading, res, error, remove, refetch } =
    useRequest<GetSubmitOrderResponse>(
      QUERY_KEYS.submitOrder,
      'post',
      Endpoints.SUBMIT_ORDER,
      requestData,
      [cachedDadaValueStr],
      true,
      accessToken
    );

  useEffect(() => {
    setDisableForm(isLoading);
    if (isLoading && !retryPayment) {
      updateFormValue('B2P', {
        initOrder: undefined,
        showInitOrder: false,
        isValid: true,
      });
      setInitOrder(undefined);
      setPaymenStep(false);
      setErrorRetry(false);
    }
  }, [isLoading]);

  useEffect(() => {
    if (!isLoading && res && accessToken) {
      setDisableForm(false);
      setOrderData(res);
      setErrorRetry(false);
      setRetryPayment(false);
      updateFormValue('DocumentsList', {
        documentsList: res.clientDocument,
        token: accessToken,
        acceptRequirements: formState?.DocumentsList?.acceptRequirements,
        isValid: formState?.DocumentsList?.isValid,
      });

      if (!error) sendAnalyticEvent(analyticEvents.flatToStepPay);
    }

    if (!isLoading && error) {
      if (hasError(error, ErrorCode.USER_DATA_ERROR)) {
        setIsPolicyBanned(true);
        navigate(PublicRoutes.POLICY_BANNED);
      }
      setDisableForm(false);
    }
  }, [isLoading, res, error, authTokens]);

  return { isLoading, res, refetch, error, remove };
};
