import { media, styled } from '@pulse-web-ui/theme';

export const Wrapper = styled.div`
  &:not(:last-child):after {
    content: '';
    display: block;
    background-color: ${({ theme }) => theme.colors.neutral[10]};
    height: 1px;
    margin: 16px 0;
    width: 100%;
  }
`;

export const OneLine = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  position: relative;

  > div:nth-child(3) {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
  }
`;

export const TwoLine = styled.div`
  margin: 12px 0 8px 0;
`;

export const ThreeLine = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;

  ${media.desktop} {
    margin-bottom: 21px;
  }
`;
