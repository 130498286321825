export enum PublicRoutes {
  ROOT = '/',
  DESCRIPTION = '/description',
  NOT_FOUND = '*',
  PAYMENT_ROUTE = '/pay',
  PAYMENT_ERROR_ROUTE = '/payment-error',
  PAYMENT_SUCCESS_ROUTE = '/payment-success',
  PAYMENT_IN_PROGRESS_ROUTE = '/payment-in-progress',
  SUCCESS_PAY = '/success-pay',
  IN_PROGRESS_PAY = '/in-progress-pay',
  FAILED_PAY = '/failed-pay',
  LOGIN = '/login',
  POLICY_BANNED = '/policy-banned',
  VIP_CLIENT = '/vip-client',
  ADDRESS_ERROR = '/address-error',
  AUTHORIZE_FAIL = '/authorize-fail',
  CHECK_USER_DATA = '/check-user-data',

  // Роут заглушка для теста логики пресет + корнер кейс
  // Удалить после реализации роута для коренер кейса
  CORNER_CASE = '/corner-case',
}
