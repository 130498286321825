export type UpdateProfileRequest = {
  clientChange: {
    email?: string;
    birthDate?: string;
    firstName?: string;
    middleName?: string;
    lastName?: string;
  };
};

export enum ClientCategory {
  VIP = 'VIP',
  STANDART = 'STANDART',
}

export type UpdateProfileResponse = {
  hasSubscriptions: boolean;
  profile: {
    userId: string;
    lastName: string;
    firstName: string;
    middleName: string;
    birthDate: string;
    phone: string;
    email: string;
    primaryRecordId: number;
    intermediary: {
      campaign: string;
      mediaSource: string;
    };
    clientCategory: ClientCategory;
    scoringValue: string;
    applicationStatus: string;
  };
  paymentInstruments: {
    id: string;
    name: string;
    imageUrl: string;
  }[];
  lockedFields: {
    lastName: boolean;
    firstName: boolean;
    middleName: boolean;
    birthDate: boolean;
    email: boolean;
    phone: boolean;
  };
};
