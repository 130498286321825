import { observer } from 'mobx-react-lite';
import { Suspense, forwardRef, useMemo } from 'react';
import type { Ref } from 'react';
import { useTranslation } from 'react-i18next';
import type { SmartComponentAdapterProps } from 'smart-components';

import type { InsuredAmountConfigurationValues } from '@pulse-smart-components-kit/insured-amount-configuration';

import { useStores, useWindowSize } from '@shared/hooks';

import { SmartInsuranceAmountConfiguration } from '@entities/import-smart-components/smart-insurance-amount-configuration';

import { StyledWrapper } from './insurance-period-and-limits-adapter.style';
import { InsurancePeriodAndLimitsSkeleton } from './skeleton';
import { prepareConfigurationOptions } from './utils';
import { preparePrices } from './utils/prepare-prices';

export const InsurancePeriodAndLimitsAdapter = observer(
  forwardRef<
    HTMLDivElement,
    SmartComponentAdapterProps<InsuredAmountConfigurationValues>
  >(({ isSubmitting, onChange, value }, forwardRef: Ref<HTMLDivElement>) => {
    const {
      MainStore: {
        authStore: { disableForm },
        productStore: { price },
        themesStore: { presetValues },
        initProductStore: { initState },
      },
    } = useStores();
    const { t } = useTranslation();

    // TODO: вынести обработчик useHandleGetSubobjects в общий обработчик бфф-бокс

    const [windowWidth] = useWindowSize();

    const pricesOptions = useMemo(() => {
      return preparePrices(windowWidth, price.prices);
    }, [windowWidth, price.prices]);

    const options = useMemo(
      () => ({
        options: prepareConfigurationOptions(initState?.subobjects, value),
        subTitle: t('INSURANCE_PERIOD_AND_LIMITS:label.subTitle'),
        title: t('INSURANCE_PERIOD_AND_LIMITS:label.title'),
        defaultPeriod:
          value?.period ??
          presetValues?.InsurancePeriodAndLimits?.defaultContractDuration ??
          price.defaultContractDuration,
        periodOptions: pricesOptions ?? [],
      }),
      [
        initState?.subobjects,
        value,
        value?.period,
        price.defaultContractDuration,
        pricesOptions,
      ]
    );

    if (disableForm || !initState?.subobjects || !pricesOptions?.length)
      return <InsurancePeriodAndLimitsSkeleton />;

    return (
      <Suspense fallback={<InsurancePeriodAndLimitsSkeleton />}>
        <StyledWrapper>
          <SmartInsuranceAmountConfiguration
            disabled={disableForm}
            isSubmitting={isSubmitting}
            onChange={onChange}
            ref={forwardRef}
            options={options}
          />
        </StyledWrapper>
      </Suspense>
    );
  })
);

InsurancePeriodAndLimitsAdapter.displayName = 'InsurancePeriodAndLimitsAdapter';
