import { useEffect } from 'react';
import { useStores } from '@shared/hooks';
import { Endpoints } from '@shared/constants';
import { useQuery } from 'react-query';
import { fetchData } from '@shared/utils';
import type { DadataResponse } from '../insurance-address-adapter.types';

export const useHandleDadata = () => {
  const {
    MainStore: {
      initProductStore: { setInitState, initState },
      productStore: { selectedRegion },
    },
  } = useStores();

  const { data, isLoading, refetch, error } = useQuery(
    ['formIFLDadata', [selectedRegion?.region]],
    fetchData<DadataResponse>({
      url: Endpoints.GET_DADATA,
      method: 'post',
      data: {
        query: selectedRegion?.region,
        from_bound: { value: 'region' },
        to_bound: { value: 'region' },
      },
    }),
    { enabled: true }
  );

  useEffect(() => {
    if (!isLoading && data) {
      setInitState({ ...initState, ...{ data } });
    }
  }, [isLoading, data]);

  return { refetch, data, isLoading, error };
};
