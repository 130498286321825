/* eslint-disable indent */
import { periodNames } from '@shared/constants';
import type { SelectedDuration } from '@shared/types';
import { useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { getDiscount } from '@shared/utils';
import { Price } from '@shared/queries/application/get-price/types';

interface Props {
  prices: Price[];
  selectedPeriod?: SelectedDuration;
}

export const PromoCodeDiscount = ({ prices, selectedPeriod }: Props) => {
  const { t } = useTranslation();

  const selectedPrice = useMemo(
    () => prices.find((price) => price.duration === selectedPeriod),
    [prices]
  );

  if (selectedPrice && selectedPrice.premiumAndDeltaPromo) {
    const selectedPeriodDiscount = getDiscount(
      selectedPrice.premiumAndDelta,
      selectedPrice.premiumAndDeltaPromo
    );

    return (
      <Trans values={{ discount: selectedPeriodDiscount }}>
        {t('COMMON:hints.discountOnSubscription')}
      </Trans>
    );
  }

  const activePeriods = useMemo(
    () =>
      prices
        .filter((price) => price.premiumAndDeltaPromo)
        .map((price) => periodNames[price.duration]),
    [prices]
  );

  if (activePeriods.length) {
    const subscriptionsText =
      activePeriods.length > 1
        ? t('COMMON:declensions.subscription2')
        : t('COMMON:declensions.subscription');

    return (
      <Trans
        values={{
          subscription: subscriptionsText,
          periods: activePeriods.join(` ${t('COMMON:hints.and')} `).toLowerCase(),
        }}
      >
        {t('COMMON:hints.discountForPeriods')}
      </Trans>
    );
  }

  return null;
};
