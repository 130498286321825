export const insuranceAddress = {
  InsuranceAddress: {
    errors: {
      specifyRegion: 'Укажите регион',
      specifyLocality: 'Укажите населенный пункт',
      specifyHouse: 'Укажите дом',
      specifyApartment: 'Укажите номер квартиры',
    },
  },
};
