import { Skeleton } from '@pulse-web-ui/skeleton';
import { OneLine, ThreeLine, TwoLine, Wrapper } from './repeating-block.styles';

export const RepeatingBlock = () => {
  return (
    <Wrapper>
      <OneLine>
        <Skeleton width={198} height={24} borderRadius={24} />
        <Skeleton height={20} width={20} borderRadius={10} />
        <Skeleton height={28} width={46} borderRadius={24} />
      </OneLine>
      <TwoLine>
        <Skeleton height={56} borderRadius={12} />
      </TwoLine>
      <ThreeLine>
        <Skeleton width={63} height={20} borderRadius={24} />
        <Skeleton width={85} height={20} borderRadius={24} />
      </ThreeLine>
      <Skeleton width={141} height={20} borderRadius={24} />
    </Wrapper>
  );
};
