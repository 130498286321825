import { observer } from 'mobx-react-lite';
import React, { FC, type Ref, Suspense, forwardRef, useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import type { SmartComponentAdapterProps } from 'smart-components';

import type {
  CheckPolicyOptions,
  CheckPolicyValues,
} from '@pulse-smart-components-kit/check-policy';

import { FallbackSkeleton } from '@shared/components';
import { useHandleDownloadPDF, useStores } from '@shared/hooks';
import type { SubObject } from '@shared/types';
import { getAddress } from '@shared/utils';

import { SmartCheckPolicy } from '@entities/import-smart-components/smart-check-policy';

import { useHandleGetSubobjects } from '../insurance-period-and-limits-adapter/queries';
import { TERMS_POLICY } from './constants';
import { i18nDefaultValues } from './i18n';
import { formCheckPolicySchema } from './schema';

export const CheckPolicyAdapter: FC<
  SmartComponentAdapterProps<CheckPolicyValues>
> = observer(
  forwardRef(
    (
      { value, onChange, isSubmitting, fieldState },
      forwardRef: Ref<HTMLDivElement>
    ) => {
      const {
        MainStore: {
          applicationStore: { paymenStep },
          productStore: { formState, orderData, dadaValueStr },
          authStore: {
            profile: { profile },
            disableForm,
            accessToken,
          },
        },
      } = useStores();
      const navigate = useNavigate();

      const { t } = useTranslation();

      const isAcceptCheckboxDisabled = disableForm || paymenStep;

      const { handleDownloadClick, documentsStatusList } = useHandleDownloadPDF(
        accessToken || '',
        orderData?.clientDocument
      );

      const userFullName = useMemo(() => {
        if (profile) {
          return `${profile.lastName} ${profile.firstName} ${profile.middleName}`;
        }
      }, [profile]);

      const risks = useMemo(
        () =>
          formState?.InsuranceRisks?.risks?.reduce((acc, item) => {
            if (item.active) {
              acc.push(item.name);
            }
            return acc;
          }, [] as string[]),
        [formState?.InsuranceRisks?.risks]
      );

      const { data } = useHandleGetSubobjects();

      const [totalInsuranceSum, subObjects] = useMemo(() => {
        const subObjects = [];

        let totalInsuranceSum = 0;

        for (const code in formState?.InsurancePeriodAndLimits?.limits) {
          const insuranceSum =
            formState?.InsurancePeriodAndLimits?.limits[code];

          const subObjectName = data?.subObjects?.find(
            (subObject: SubObject) => subObject.code === code
          )?.name;

          subObjects.push({
            title: subObjectName || '',
            value: t('CHECK_POLICY:label.productItem.value', {
              summ: insuranceSum.toString(),
            }),
          });

          totalInsuranceSum += insuranceSum;
        }

        return [totalInsuranceSum, subObjects];
      }, [data?.subObjects]);

      const addressPrepared = dadaValueStr
        ? getAddress(formState?.InsuranceAddress, dadaValueStr)
        : undefined;

      const options: CheckPolicyOptions = useMemo(
        () => ({
          onDownload: handleDownloadClick,
          documentsList: orderData?.clientDocument,
          policyIncludedConfig: {
            coverageSum: totalInsuranceSum,
            subTitle: t('CHECK_POLICY:label.subTitle', {
              coverage: subObjects.length,
              risks: risks?.length,
            }),
            coverageSumTitle: t('CHECK_POLICY:label.coverageSumTitle'),
            productItems: subObjects,
            listTitle: t('CHECK_POLICY:label.listTitle'),
            listItems: risks,
          },
          documentsStatusList,
          insuranceEntitiesConfig: {
            items: [
              {
                title: t('CHECK_POLICY:label.insurerTitle'),
                key: 1,
                description: (
                  <>
                    {userFullName}
                    <br />
                    {profile.email}
                  </>
                ),
              },
              {
                title: t('CHECK_POLICY:label.flatAddress'),
                key: 2,
                description: <>{addressPrepared?.address}</>,
              },

              {
                title: t('CHECK_POLICY:label.startDateTitle'),
                key: 3,
                description: (
                  <>{formState.StartDate?.date?.toLocaleDateString('ru-RU')}</>
                ),
              },
            ],
          },
          acceptTermsConfig: {
            title: (
              <Trans
                components={{
                  AcceptTermsLink: <a />,
                }}
                values={{ href: TERMS_POLICY }}
              >
                {t('SMART:CheckPolicy.labels.acceptTermsPolicy', {
                  defaultValue:
                    i18nDefaultValues.CheckPolicy.labels.acceptTermsPolicy,
                })}
              </Trans>
            ),
          },
        }),
        [
          orderData?.clientDocument,
          documentsStatusList,
          totalInsuranceSum,
          subObjects,
          risks,
        ]
      );

      return (
        <Suspense fallback={<FallbackSkeleton height={460} />}>
          {/* Заглушка для теста логики пресет + корнер кейс
            Удалить после реализации целевой логики для коренер кейса*/}
          <button
            style={{ marginTop: '48px' }}
            type="button"
            onClick={() => navigate('/corner-case')}
          >
            Перейти на экран корнер кейса
          </button>
          <SmartCheckPolicy
            ref={forwardRef}
            value={value}
            onChange={onChange}
            isSubmitting={isSubmitting}
            options={options}
            disabled={isAcceptCheckboxDisabled}
            fieldState={fieldState}
            formSchema={formCheckPolicySchema}
            onAnalyticEventSend={() => {
              return;
            }}
          />
        </Suspense>
      );
    }
  )
);

CheckPolicyAdapter.displayName = 'CheckPolicyAdapter';
