import { media, styled } from '@pulse-web-ui/theme';

export const OneBlock = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 16px;

  ${media.desktop} {
    align-items: center;
    margin-bottom: 24px;
  }
`;

export const TwoBlock = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  gap: 4px;
  margin-bottom: 8px;

  ${media.desktop} {
    grid-template-columns: 1fr 1fr;
    gap: 8px;
    margin-bottom: 16px;
  }
`;
