import type { Price } from '@shared/queries';

export const checkPrices = (
  oldPrice: Price,
  newPrice: Price,
  hasPromo: boolean
) =>
  hasPromo
    ? oldPrice.premiumAndDeltaPromo === newPrice.premiumAndDeltaPromo
    : oldPrice.premiumAndDelta === newPrice.premiumAndDelta;
