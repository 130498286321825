import { Button } from '@pulse-web-ui/button';
import { media, styled, typography } from '@pulse-web-ui/theme';

export const SubTitle = styled.div`
  ${typography.medium16}
  line-height: 24px;
`;

export const TotalSum = styled.div`
  ${typography.medium20}
  margin-top: 4px;
`;

export const SubscriptionDataWrapper = styled.div`
  margin-top: 24px;
`;

export const SubscriptionPriceWrapper = styled(SubscriptionDataWrapper)`
  border-top: 1px solid ${({ theme }) => theme.colors.neutral[30]};
  padding-top: 16px;
`;

export const SubscriptionDataContent = styled.div`
  margin-top: 8px;
`;

export const SubscriptionDataContentItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 10px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.neutral[30]};
  padding: 8px 0;
`;

export const SubObjectPrice = styled.div`
  ${typography.medium14}
  line-height: 20px;
  min-width: 95px;
  text-align: left;
`;

export const SubObjectName = styled.div`
  ${typography.regular14}
  line-height: 20px;
  color: ${({ theme }) => theme.colors.text.secondary};
`;

export const SubscriptionDataRiskText = styled.li`
  ${typography.regular16}
  line-height: 24px;
  width: 100%;
`;

export const SubscriptionPrice = styled.span`
  ${typography.medium20}
`;

export const SubscriptionOldPrice = styled.span`
  ${typography.regular16}
  line-height: 24px;
  text-decoration: line-through;
  color: ${({ theme }) => theme.colors.text.secondary};
  margin-left: 8px;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 100%;
  margin-top: 24px;

  ${media.desktop} {
    flex-direction: row;
    margin-top: 32px;
  }
`;

export const AdaptiveButton = styled(Button)`
  width: 100%;
  ${media.desktop} {
    width: auto;
  }
`;
