import { WidgetContainer } from '@pulse-web-ui/containers';
import { FourBlock, OneBlock, ThreeBlock, TwoBlock } from './skeleton.styles';
import { Skeleton } from '@pulse-web-ui/skeleton';
import { useIsDesktop } from '@shared/hooks';
import { RepeatingBlock } from './repeating-block';

export const InsurancePeriodAndLimitsSkeleton = () => {
  const isDesktop = useIsDesktop();
  return (
    <WidgetContainer>
      <OneBlock>
        <Skeleton width={181} height={32} borderRadius={32} />
        <Skeleton height={40} borderRadius={32} />
      </OneBlock>
      <TwoBlock>
        <Skeleton
          width={isDesktop ? 426 : undefined}
          height={32}
          heightMobile={56}
          borderRadius={24}
        />
        <Skeleton
          width={isDesktop ? 567 : undefined}
          height={24}
          heightMobile={48}
          borderRadius={24}
        />
      </TwoBlock>
      <ThreeBlock>
        <Skeleton height={58} heightMobile={56} borderRadius={16} />
      </ThreeBlock>
      <FourBlock>
        {Array.from({ length: 4 }).map((_, idx) => (
          <RepeatingBlock key={idx} />
        ))}
      </FourBlock>
    </WidgetContainer>
  );
};
