/* eslint-disable indent */
import { updateFormByMobileApp } from '@shared/utils';
import { useQueryErrorResetBoundary } from 'react-query';
import {
  useStores,
  useGlobalFetching,
  useHandleErrorRetry,
  useScoringUser,
} from '@shared/hooks';
import {
  useCompletePayment,
  useUpdateProfile,
  useInitOrder,
  getCompletePaymentData,
} from '@shared/queries';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import type { FC } from 'react';
import {
  useHandleSubmitOnPaymentPage,
  useHandleWhoAndHowPaperwork,
  useHandleFooter,
  useHandleB2P,
} from './smart-components-hooks';
import { useHandleNextStep } from './hooks';
import type { FormBuilderUpdateHandler } from '@features/form-builder';

type BFFBoxProps = {
  updateFormValue: FormBuilderUpdateHandler;
};

export const BFFBox: FC<BFFBoxProps> = observer(({ updateFormValue }) => {
  const {
    MainStore: {
      applicationStore: { deviceType, phoneFromMobileApp },
      initProductStore: { formBuilderIsMounted },
      authStore: { setDisableForm },
      errorStore: { setErrorRetry },
    },
  } = useStores();

  const isGlobalFetching = useGlobalFetching();

  // Smart components hooks
  useHandleSubmitOnPaymentPage(updateFormValue);
  useHandleWhoAndHowPaperwork(updateFormValue);
  useHandleFooter();

  // Queries hooks
  const updateProfileRefetch = useUpdateProfile();

  // Payment hooks
  const { resSubmitOrder, refetchSubmitOrder } = useHandleB2P(updateFormValue);
  const { refetchInit, resInit } = useInitOrder(
    updateFormValue,
    resSubmitOrder
  );
  const refetchCompletePayment = useCompletePayment(
    getCompletePaymentData(resSubmitOrder, resInit)
  );

  // Other hooks
  useHandleNextStep(updateProfileRefetch);
  useScoringUser();

  useEffect(() => {
    setDisableForm(isGlobalFetching);
  }, [isGlobalFetching]);

  const { reset } = useQueryErrorResetBoundary();

  const selectRefetch = (index: string) => {
    reset();
    setErrorRetry(false);
    switch (index) {
      case 'INIT_ORDER':
        return refetchInit();
      case 'UPDATE_PROFILE':
        return updateProfileRefetch();
      case 'COMPLETE_PAYMENT':
        return refetchCompletePayment();
      case 'SUBMIT_ORDER':
        return refetchSubmitOrder();
    }
  };

  useHandleErrorRetry(selectRefetch);

  // Добавление данных с мп в смарт компонент
  useEffect(() => {
    if (!formBuilderIsMounted) return;
    updateFormByMobileApp(updateFormValue, deviceType, phoneFromMobileApp);
  }, [phoneFromMobileApp, deviceType, formBuilderIsMounted]);

  return null;
});
