import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Endpoints, QUERY_KEYS, ErrorCode } from '@shared/constants';
import { useRequest, useStores } from '@shared/hooks';
import { PublicRoutes } from '@shared/routes';
import { hasError } from '@shared/utils';

import {
  AuthBoxAnalyticEvent,
  type AuthenticationResponseType,
  type OnAnalyticEventSend,
} from '../auth-box.type';

export const useHandleConfirmAuthentication = (
  setFormValueError: (fieldName: string, error: string) => void,
  isLoadingAuthentication?: boolean,
  resAuthentication?: AuthenticationResponseType,
  onAnalyticEventSend?: OnAnalyticEventSend
) => {
  const {
    MainStore: {
      errorStore: { setErrorRetry },
      authStore: {
        callOtp,
        authenticationToken,
        profile,
        confirmationId,
        setDisableForm,
        setOtpIsLoading,
        setOtpErrorCode,
        handleOtpResponse,
        setUseOtp,
      },
    },
  } = useStores();

  const navigate = useNavigate();
  const { t } = useTranslation();

  const requestData = {
    code: callOtp,
    confirmationId: resAuthentication?.confirmationId,
  };

  const { isLoading, error, res, refetch, remove } = useRequest(
    QUERY_KEYS.confirmAuth,
    'post',
    Endpoints.CONFIRMAUTH,
    requestData,
    [callOtp, resAuthentication?.authenticationToken],
    true,
    authenticationToken
  );

  useEffect(() => {
    const shouldRefetchOtp =
      callOtp &&
      !profile &&
      confirmationId &&
      resAuthentication &&
      authenticationToken &&
      !isLoadingAuthentication;

    if (shouldRefetchOtp) {
      setDisableForm(true);
      refetch();
    }
  }, [
    profile,
    callOtp,
    confirmationId,
    resAuthentication,
    authenticationToken,
    isLoadingAuthentication,
  ]);

  const handleRateLimitError = useCallback((hasUnlockingTime: boolean) => {
    if (hasUnlockingTime) {
      setUseOtp(false);
      setFormValueError(
        'WhoIssuesPolicy.phone',
        t('COMMON:errors.authLimitExceeded')
      );
    } else {
      navigate(PublicRoutes.AUTHORIZE_FAIL);
    }
  }, []);

  useEffect(() => {
    setOtpIsLoading(isLoading);

    if (!isLoading) {
      if (res && res.accessToken) {
        handleOtpResponse(res);
        setOtpErrorCode(false);
        setErrorRetry(false);
        onAnalyticEventSend &&
          onAnalyticEventSend(AuthBoxAnalyticEvent.ON_ENTER_CODE_SUCCESS);
      }
      if (hasError(error, ErrorCode.UNKNOWN_USER_OR_CODE)) {
        setOtpErrorCode(true);
      }
      if (hasError(error, ErrorCode.RATE_LIMIT_EXCEEDED)) {
        setOtpErrorCode(true);
        handleRateLimitError(!!error?.response?.data?.unlockingTime);
      }
    }
  }, [isLoading, error, res, handleRateLimitError]);

  return {
    isLoadingConfirmAuthentication: isLoading,
    errorConfirmAuthentication: error,
    resConfirmAuthentication: res,
    refetchConfirmAuthentication: refetch,
    removeConfirmAuthentication: remove,
  };
};
