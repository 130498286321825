import type { InsuranceSubObject } from '../check-user-data.types';

interface InsuranceConvertedSubProduct {
  code: string;
  insuranceSum: string;
}

export const subObjectConverter = (subobjects: InsuranceSubObject[]) => {
  const objects: InsuranceConvertedSubProduct[] = [];

  subobjects.map((item) => {
    if (Number(item.defaultInsuranceSum) !== 0) {
      objects.push({
        code: item.code,
        insuranceSum: item.defaultInsuranceSum,
      });
    }
  });
  return objects;
};
