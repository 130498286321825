/* eslint-disable indent */
import { InsuranceRisksValues } from '@pulse-smart-components-kit/insurance-risks';

import type { RisksResponse } from './insurance-risks-adapter.types';

export const getOptionsValue = (
  data: RisksResponse['risks'],
  value?: InsuranceRisksValues
) => ({
  risks: !!value?.risks?.length
    ? data.map((risk) => {
        const selectedRisk = value.risks.find(({ code }) => code === risk.code);

        return {
          ...risk,
          active: selectedRisk ? selectedRisk.active : risk.active,
        };
      })
    : data,
  isValid: true,
});
