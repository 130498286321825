import { WidgetContainer } from '@pulse-web-ui/containers';
import { Skeleton } from '@pulse-web-ui/skeleton';

import { OneBlock, TwoBlock } from './skeleton.styles';

export const InsuranceRisksSkeleton = () => {
  return (
    <WidgetContainer>
      <OneBlock>
        <Skeleton
          width={256}
          height={32}
          widthMobile={213}
          heightMobile={28}
          borderRadius={24}
        />
        <Skeleton
          width={100}
          height={26}
          widthMobile={89}
          heightMobile={24}
          borderRadius={24}
        />
      </OneBlock>
      <TwoBlock>
        {Array.from({ length: 4 }).map((_, idx) => (
          <Skeleton key={idx} borderRadius={12} height={56} />
        ))}
      </TwoBlock>
      <Skeleton width={123} height={26} />
    </WidgetContainer>
  );
};
