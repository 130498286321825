import { styled } from '@pulse-web-ui/theme';

export const OneBlock = styled.div`
  margin-bottom: 16px;
`;

export const TwoBlock = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;
