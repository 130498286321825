export const QUERY_KEYS = {
  getPrices: 'get-prices',
  auth: 'auth',
  confirmAuth: 'confirmAuth',
  getProfile: 'getProfile',
  registration: 'registration',
  submitOrder: 'submitOrder',
  updateProfile: 'updateProfile',
  initOrder: 'initOrder',
  completePayment: 'completePayment',
  getRisk: 'getRisk',
  scoring: 'scoring',
};

export const DOCUMENT_QUERY_KEY = 'kei-file';
