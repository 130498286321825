import { WhoIssuesPolicy } from '@entities/import-smart-components/who-Issues-policy';
import { FallbackSkeleton } from '@shared/components';
import { useStores } from '@shared/hooks';
import type { AnalyticEventsMap } from '@shared/types';
import { observer } from 'mobx-react-lite';
import { Suspense, useMemo, forwardRef, useCallback } from 'react';
import type { FC, Ref } from 'react';
import type {
  SmartComponentAdapterProps,
  WhoIssuesPolicyValues,
  WhoIssuesPolicyOptions,
} from 'smart-components';
import { WhoIssuesPolicyAnalyticEvent } from 'smart-components/who-issues-policy';
import {
  analyticEvents,
  useHandleSmartComponentsAnalyticEvents,
} from '@app/web-analytic';

const analyticEventsMap: AnalyticEventsMap<WhoIssuesPolicyAnalyticEvent> = {
  [WhoIssuesPolicyAnalyticEvent.ON_PHONE_BLUR]: {
    name: analyticEvents.flatPhoneEntered,
  },
  [WhoIssuesPolicyAnalyticEvent.ON_EMAIL_BLUR]: {
    name: analyticEvents.flatEmailEntered,
  },
  [WhoIssuesPolicyAnalyticEvent.ON_USER_BLUR]: {
    name: analyticEvents.flatInsurerDataEntered,
  },
};

export const WhoIssuesPolicyAdapter: FC<
  SmartComponentAdapterProps<WhoIssuesPolicyValues>
> = observer(
  forwardRef(
    (
      { value, onChange, isSubmitting, fieldState },
      forwardRef: Ref<HTMLDivElement>
    ) => {
      const {
        MainStore: {
          applicationStore: { deviceType, phoneFromMobileApp, updateFormValue },
          authStore: { disableForm, disableProfileState },
          productStore: {
            agentLogin,
            formState: { WhoAndHowPaperwork },
          },
        },
      } = useStores();

      const handleChangeDate = useCallback(
        (newBirthDate: Date) => {
          if (WhoAndHowPaperwork?.travelers) {
            const travelers = [...WhoAndHowPaperwork.travelers];
            travelers[0] = { ...travelers[0], birthday: newBirthDate };
            updateFormValue('WhoAndHowPaperwork', {
              ...WhoAndHowPaperwork,
              travelers,
            });
          }
        },
        [WhoAndHowPaperwork]
      );

      const options: WhoIssuesPolicyOptions = useMemo(
        () => ({
          deviceType,
          phoneFromMobileApp,
          agentLogin,
          disableProfileState,
          onChangeAuthorizedBirthDate: handleChangeDate,
        }),
        [
          deviceType,
          phoneFromMobileApp,
          agentLogin,
          disableProfileState,
          handleChangeDate,
        ]
      );

      const handleAnalyticEventSend =
        useHandleSmartComponentsAnalyticEvents<WhoIssuesPolicyAnalyticEvent>(
          analyticEventsMap
        );

      return (
        <Suspense fallback={<FallbackSkeleton height={340} />}>
          <WhoIssuesPolicy
            ref={forwardRef}
            value={value}
            onChange={onChange}
            isSubmitting={isSubmitting}
            options={options}
            fieldState={fieldState}
            disabled={disableForm}
            onAnalyticEventSend={handleAnalyticEventSend}
          />
        </Suspense>
      );
    }
  )
);

WhoIssuesPolicyAdapter.displayName = 'WhoIssuesPolicyAdapter';
