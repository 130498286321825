import { Endpoints, PRODUCT_CODE } from '@shared/constants';
import { useRequest, useStores } from '@shared/hooks';
import { useEffect } from 'react';

export const useScoringUser = () => {
  const {
    MainStore: {
      authStore: { profile, authTokens, setIsScoringSuccess },
    },
  } = useStores();

  const { refetch } = useRequest(
    'userScoringRequest',
    'post',
    Endpoints.SCORING_USER,
    { productCode: PRODUCT_CODE },
    [authTokens?.authorization?.accessToken],
    true,
    authTokens?.authorization?.accessToken
  );

  useEffect(() => {
    if (profile?.profile.firstName) {
      refetch().then(({ status }) => {
        setIsScoringSuccess(status === 'success');
      });
    }
  }, [profile?.profile.firstName]);
};
