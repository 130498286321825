import { DEFAULT_FIAS_ID, Endpoints, QUERY_KEYS } from '@shared/constants';
import { useRequest, useStores } from '@shared/hooks';
import { addressStringExtractor } from '@shared/utils';

export const useScoringAddress = () => {
  const {
    MainStore: {
      initProductStore: { initState },
      productStore: { cachedDadaValueStr, dadaValueStr },
      authStore: { accessToken },
    },
  } = useStores();

  // TODO:  переписать на прямой вызов useQuery

  const InsuranceAddressData = dadaValueStr ? JSON.parse(dadaValueStr) : '';
  const PreparedAddress = addressStringExtractor(InsuranceAddressData);

  const { isLoading, error, status, refetch, remove } = useRequest(
    QUERY_KEYS.scoring,
    'post',
    Endpoints.SCORING,
    {
      productCode: initState.code,
      address: {
        address: PreparedAddress,
        addressCode: InsuranceAddressData?.data?.fias_id || DEFAULT_FIAS_ID,
      },
    },
    [cachedDadaValueStr],
    true,
    accessToken
  );

  return { refetch, status, isLoading, error, remove };
};
