import { observer } from 'mobx-react-lite';
import {
  Suspense,
  forwardRef,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from 'react';
import type { FC, Ref } from 'react';
import { FieldValues } from 'react-hook-form';
import type { SmartComponentAdapterProps } from 'smart-components';
import * as yup from 'yup';

import type { InsuranceAddressValues } from '@pulse-smart-components-kit/insurance-address';
import InsuranceAddress from '@pulse-smart-components-kit/insurance-address';

import type { DaDataAddressSuggestion } from '@pulse-web-ui/dadata';

import i18n from '@app/i18n/config';

import { EMPTY_REGION, Product, UseQueryStatus } from '@shared/constants';
import { useStores } from '@shared/hooks';

import { useHandleDadata, useScoringAddress } from './hooks';
import {
  cityDefaultValue,
  flatDefaultValue,
  houseDefaultValue,
  settlementDefaultValue,
  streetDefaultValue,
} from './insurance-address-adapter.constants';
import type { InsuranceAddressOptions } from './insurance-address-adapter.types';
import { getSmartAddressSchema, useQueryFunction } from './utils';
import { InsuranceAddressSkeleton } from './skeleton';

export const InsuranceAddressAdapter: FC<
  SmartComponentAdapterProps<InsuranceAddressValues>
> = observer(
  forwardRef(
    (
      { isSubmitting, fieldState, value, onChange },
      forwardRef: Ref<HTMLDivElement>
    ) => {
      const {
        MainStore: {
          authStore: { disableForm },
          initProductStore: {
            initState: { regions },
            initState,
            setInitState,
          },
          applicationStore: { updateFormValue },
          productStore: {
            formState: { InsuranceAddress: InsuranceAddressStore },
            setCachedDadaValueStr,
            cachedDadaValueStr,
            setDadaValueStr,
            dadaValueStr,
            selectedRegion,
            setSelectedRegion,
          },
        },
      } = useStores();

      const [dadaValue, setdadaValue] = useState<DaDataAddressSuggestion>();

      // TODO: cornercase for object scoring
      const {
        isLoading: scoringIsLoading,
        error: scoringError,
        refetch: scoringRefetch,
        status: scoringStatus,
      } = useScoringAddress();

      useLayoutEffect(() => {
        if (!scoringIsLoading && scoringStatus === UseQueryStatus.SUCCESS) {
          setCachedDadaValueStr(JSON.stringify(dadaValue));
        }
      }, [scoringStatus, scoringIsLoading]);

      useEffect(() => {
        if (scoringError) {
          setCachedDadaValueStr(JSON.stringify(undefined));
        }
      }, [scoringError]);

      useEffect(() => {
        if (regions && regions[0] && !selectedRegion) {
          setInitState({
            ...initState,
            selectedRegion: [regions[0]],
          });
          setSelectedRegion(regions[0]);
        }

        if (dadaValueStr) {
          setdadaValue(JSON.parse(dadaValueStr));
        }
      }, []);

      const { isLoading, data } = useHandleDadata();

      const [isCheckHouseStatus, setIsCheckHouseStatus] = useState(false);
      const [isSelectedCustomHouse, setIsSelectedCustomHouse] =
        useState<boolean>(false);
      const [isSelectedCustomStreet, setIsSelectedCustomStreet] =
        useState<boolean>(false);
      const [isEmptyStreetSuggestions, setIsEmptyStreetSuggestions] =
        useState<boolean>(false);
      const [isEmptyHouseSuggestions, setIsEmptyHouseSuggestions] =
        useState<any>('');
      const [, setIsHouseNotFound] = useState<boolean>(false);

      const smartAddressSchema = useMemo(
        () =>
          getSmartAddressSchema(dadaValue).shape({
            flat: yup
              .string()
              .required(
                i18n.t('SMART:InsuranceAddress.errors.specifyApartment')
              ),
          }),
        [dadaValue]
      );

      useEffect(() => {
        const a = cachedDadaValueStr
          ? JSON.parse(cachedDadaValueStr)
          : undefined;

        // TODO: доработать валидацию формы. Необходимо переход на следующий шаг выполнять
        // только после получения ответа scoring.

        if (a?.value !== dadaValue?.value && InsuranceAddressStore?.isValid) {
          scoringRefetch();
        }
      }, [isSubmitting]);

      useEffect(() => {
        if (
          !dadaValue?.data &&
          !isLoading &&
          data &&
          !InsuranceAddressStore?.street
        ) {
          let regionDadataValue = (data as any).suggestions;

          if (Array.isArray((data as any).suggestions)) {
            regionDadataValue = (data as any).suggestions[0];
          }

          const val = {
            ...regionDadataValue,
            data: {
              ...regionDadataValue.data,
              ...cityDefaultValue,
              ...settlementDefaultValue,
              ...streetDefaultValue,
              ...houseDefaultValue,
              ...flatDefaultValue,
            },
          };
          setdadaValueHandler(val);
        }
      }, [isLoading, data, dadaValue?.data]);

      const queryFn = useQueryFunction(
        'post',
        '/v3/dadata/suggest/address',
        undefined
      );

      const storeSelectedRegionHandle = useCallback((e?: any) => {
        setSelectedRegion(e);
      }, []);

      const setdadaValueHandler = (value: any) => {
        console.log('vvv', value, InsuranceAddressStore);
        setdadaValue(value);

        const insuranceAddressValue: FieldValues = {
          ...InsuranceAddressStore,
          dadaValueStr: JSON.stringify(value),
        };
        if (value?.data?.flat) insuranceAddressValue.flat = value?.data?.flat;

        updateFormValue('InsuranceAddress', insuranceAddressValue);

        setDadaValueStr(JSON.stringify(value));
      };

      const OPTIONS: InsuranceAddressOptions = {
        regions: regions ?? EMPTY_REGION,
        selectedRegion: selectedRegion,
        isCheckHouseStatus,
        isSelectedCustomStreet,
        isSelectedCustomHouse,
        isEmptyHouseSuggestions,
        setIsHouseNotFound,
        setIsCheckHouseStatus,
        setIsEmptyHouseSuggestions,
        setIsSelectedCustomStreet,
        productType: Product.APARTMENT,
        storeSelectedRegion: storeSelectedRegionHandle,
        isEmptyStreetSuggestions,
        setIsEmptyStreetSuggestions,
        setIsSelectedCustomHouse,
        dadaValue,
        dadataCB: queryFn,
        setDadaValue: setdadaValueHandler as any,
      };

      return (
        <Suspense fallback={<InsuranceAddressSkeleton />}>
          <InsuranceAddress
            onChange={onChange}
            value={value}
            ref={forwardRef}
            disabled={disableForm}
            fieldState={fieldState}
            formSchema={smartAddressSchema as any}
            isSubmitting={isSubmitting}
            options={OPTIONS}
          />
        </Suspense>
      );
    }
  )
);

InsuranceAddressAdapter.displayName = 'InsuranceAddressAdapter';
