import { checkPolicy } from './check-policy';
import { insuranceAddress } from './insurance-address';
import { commonNS, commonNamespaces } from './common';
import { smartHeaderNS } from './smart-header';
import { startDate } from './start-date';

const smartNamespaces = 'SMART';

export const resources = {
  ru: {
    ...commonNS,
    [smartNamespaces]: {
      ...smartHeaderNS,
      ...checkPolicy,
      ...insuranceAddress,
      ...startDate,
    },
  },
};

export const namespaces = [...commonNamespaces, smartNamespaces];
