import type { InsuranceSubObject } from '../check-user-data.types';

export const getCheckedSubobjects = (
  oldSubobjects: InsuranceSubObject[],
  newSubobjects: InsuranceSubObject[],
  selectedLimits: Record<string, number>
) => {
  const filteredOldSubobjects = oldSubobjects.filter(
    (item) => selectedLimits[item.code] !== 0
  );
  const allConditionsMet = filteredOldSubobjects.every((item) => {
    const limit = newSubobjects.find(
      (limitItem) => limitItem.code === item.code
    );

    if (limit) {
      const insuranceSum = isNaN(selectedLimits[item.code])
        ? Number(item.defaultInsuranceSum)
        : selectedLimits[item.code];

      return insuranceSum >= limit.minLimit && insuranceSum <= limit.maxLimit;
    }
    return false;
  });

  const checkedSubobjects: InsuranceSubObject[] = filteredOldSubobjects.map(
    (oldSubobject) => {
      const newSubObject =
        newSubobjects.find(({ code }) => code === oldSubobject.code) ||
        oldSubobject;
      const insuranceSum = isNaN(Number(selectedLimits[oldSubobject.code]))
        ? Number(oldSubobject.defaultInsuranceSum)
        : Number(selectedLimits[oldSubobject.code]);
      const minLimit = Number(newSubObject.minLimit);
      const maxLimit = Number(newSubObject.maxLimit);

      if (insuranceSum < minLimit) {
        return {
          ...newSubObject,
          defaultInsuranceSum: minLimit.toString(),
        };
      }

      if (insuranceSum > maxLimit) {
        return {
          ...newSubObject,
          defaultInsuranceSum: maxLimit.toString(),
        };
      }

      return {
        ...newSubObject,
        defaultInsuranceSum: String(insuranceSum),
      };
    }
  );

  return {
    hasChanged: !allConditionsMet,
    checkedSubobjects,
  };
};
