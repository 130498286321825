import type { RisksResponse } from '@entities/adapters';

export const getActiveCodeRisks = (
  risks?: RisksResponse['risks'],
  isHasLiability?: boolean
) => {
  if (!risks) return;

  const preparedRisks = risks.reduce<Array<string>>((acc, el) => {
    if (el.active) acc.push(el.code);
    return acc;
  }, []);

  if (isHasLiability && !preparedRisks.includes('Liability_1'))
    preparedRisks.push('Liability_1');

  return preparedRisks;
};
