/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable prettier/prettier */
import { AxiosRequestConfig, AxiosResponse } from 'axios';
import { v4 as uuidv4 } from 'uuid';

import type { Method, Url } from '@shared/types';
import { axiosInstance } from '@shared/utils';
import { PlatformType } from '@shared/constants';

const customFetch = async <T, D>({
  method,
  url,
  request,
  config,
}: {
  method: Method;
  url: Url;
  request?: D;
  config: AxiosRequestConfig<D>;
}) => {
  if (method === 'get') {
    return axiosInstance.get<D, AxiosResponse<T>>(url, config);
  }

  return axiosInstance[method]<D, AxiosResponse<T>>(url, request, config);
};

export const useQueryFunction = <T = any, D = any>(
  method: Method,
  url: Url,
  request?: D,
  requestConfig?: AxiosRequestConfig<D>,
) => {
  const headers = {
    'X-Process-ID': uuidv4(),
    'X-Platform-Type': PlatformType,
    'Content-type': 'application/json',
  };

  const config = {
    headers,
    ...requestConfig,
  };

  const queryFn = async (data: any) => {
    return customFetch<T, D>({ method, url, request: request || data, config: requestConfig ? requestConfig : config });
  };

  return queryFn;
};
