import { useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { type ConfigurationSubObject } from '@pulse-smart-components-kit/insured-amount-configuration';

import { DEFAULT_REGION_FIAS_ID, Endpoints } from '@shared/constants';
import { useRequest, useStores } from '@shared/hooks';
import { PublicRoutes } from '@shared/routes';
import { ErrorCode } from '@shared/constants';

export const useHandleGetSubobjects = () => {
  const {
    MainStore: {
      initProductStore: { setInitState, initState },
      applicationStore: { activeStep },
      authStore: { authTokens, setDisableForm },
      productStore: { formState },
      themesStore: { presetValues },
    },
  } = useStores();

  const navigate = useNavigate();

  const risks = useMemo(
    () =>
      (formState?.InsuranceRisks?.risks || initState.risks)?.reduce<
        Array<string>
      >((acc, item) => {
        if (item.active) {
          acc.push(item.code);
        }
        return acc;
      }, []),
    [initState.risks]
  );

  const enabled = useMemo(() => {
    return Boolean(risks?.length) && Boolean(initState.code);
  }, [risks?.length, initState.code]);

  const {
    res: data,
    error: subobjectsError,
    refetch,
    isLoading,
  } = useRequest(
    'formIFLGetSubobjects',
    'post',
    Endpoints.GET_SUBOBJECTS,
    {
      productCode: initState.code,
      region: formState.InsuranceAddress?.region || DEFAULT_REGION_FIAS_ID,
      risks: risks ? [...risks, 'Liability_1'] : [],
    },
    [
      initState.code,
      risks,
      authTokens?.authorization?.accessToken,
      formState.InsuranceAddress?.region,
    ],
    true,
    authTokens?.authorization?.accessToken
  );

  const errorCode = subobjectsError?.response?.data?.code;

  useEffect(() => {
    if (enabled) {
      refetch();
    }
  }, [
    enabled,
    authTokens?.authorization?.accessToken,
    risks,
    formState.InsuranceAddress?.region,
  ]);

  useEffect(() => {
    if (!isLoading) {
      if (errorCode === ErrorCode.USER_DATA_ERROR) {
        setInitState({});
        navigate(PublicRoutes.POLICY_BANNED);
      }
      if (errorCode === ErrorCode.TECHNICAL_ERROR) {
        navigate(PublicRoutes.ROOT, { state: { activeStep } });
      }
      if (data) {
        let newData = data;
        if (presetValues?.InsurancePeriodAndLimits?.limits) {
          const newSubObjects = data.subObjects;

          for (const [key, val] of Object.entries(
            presetValues?.InsurancePeriodAndLimits?.limits
          )) {
            newSubObjects.map((value: ConfigurationSubObject) => {
              if (value.code === key) {
                value.defaultInsuranceSum = String(val);
              }
              return value;
            });
          }
          newData = {
            ...data,
            subObjects: newSubObjects,
          };
        }
        setInitState({ ...initState, ...{ subobjects: newData } });
      }

      setDisableForm(false);
    } else setDisableForm(true);
  }, [isLoading, data, subobjectsError]);

  return { isLoading, data, refetch };
};
