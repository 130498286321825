import { useEffect, useMemo } from 'react';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';

import {
  DEFAULT_FIAS_ID,
  DEFAULT_REGION_FIAS_ID,
  Endpoints,
  ErrorCode,
  QUERY_KEYS,
} from '@shared/constants';
import { useDebounce, useStores } from '@shared/hooks';
import { PublicRoutes } from '@shared/routes';
import type { ResponseError } from '@shared/types';
import {
  addressStringExtractor,
  fetchData,
  getActiveCodeRisks,
  getInsuranceSum,
  getSubObjects,
  hasError,
} from '@shared/utils';

import { prepareLimits } from '@entities/adapters/insurance-period-and-limits-adapter';

import type { GetPrice } from './types';

const debounceInsuranceSumTime = 1000;
const debounceRisksTime = 500;

export const useGetPrice = () => {
  const {
    MainStore: {
      errorStore: { setErrorRetry },
      initProductStore: { initState },
      productStore: {
        formState,
        price: { promoCode },
        dadaValueStr,
        setPrice,
      },
    },
  } = useStores();

  const navigate = useNavigate();

  const risks = formState.InsuranceRisks?.risks || initState.risks;
  const limits =
    formState.InsurancePeriodAndLimits?.limits ||
    prepareLimits(initState.subobjects);
  const isHasLiability = Boolean(limits?.Liability_1);

  const insuranceSum = useMemo(
    () => getInsuranceSum(limits),
    [limits, getInsuranceSum]
  );

  const subObjects = useMemo(
    () => getSubObjects(limits),
    [limits, getSubObjects]
  );

  const filteredRisks = useMemo(
    () => getActiveCodeRisks(risks, isHasLiability),
    [risks, getActiveCodeRisks, isHasLiability]
  );

  const debounceInsuranceSum = useDebounce(
    insuranceSum,
    debounceInsuranceSumTime
  );
  const debounceRisks = useDebounce(filteredRisks, debounceRisksTime);

  const isEnabledFetchingGetPrice = Boolean(
    debounceRisks && debounceInsuranceSum
  );

  const InsuranceAddressData = dadaValueStr ? JSON.parse(dadaValueStr) : '';
  const PreparedAddressString = addressStringExtractor(InsuranceAddressData);

  const { isLoading, data, refetch, error } = useQuery<GetPrice, ResponseError>(
    [
      QUERY_KEYS.getPrices,
      debounceRisks,
      debounceInsuranceSum,
      promoCode,
      formState.InsuranceAddress?.region,
      InsuranceAddressData?.data?.fias_id,
      PreparedAddressString,
    ],
    fetchData<GetPrice>({
      url: Endpoints.GET_PRICES,
      method: 'post',
      data: {
        productCode: initState.code,
        insuranceSum: debounceInsuranceSum,
        risks: debounceRisks,
        personProperties: {
          subObjects,
          address: {
            regionCode:
              formState.InsuranceAddress?.region ?? DEFAULT_REGION_FIAS_ID,
            address: PreparedAddressString,
            addressCode: InsuranceAddressData?.data?.fias_id || DEFAULT_FIAS_ID,
          },
        },
        promoCode,
      },
    }),
    {
      enabled: isEnabledFetchingGetPrice,
    }
  );

  useEffect(() => {
    if (!isLoading && data) {
      setPrice(data);
      setErrorRetry(false);
    }
    if (hasError(error, ErrorCode.OBJECT_DATA_ERROR)) {
      navigate(PublicRoutes.ADDRESS_ERROR);
    }
  }, [isLoading, data, error]);

  return refetch;
};
