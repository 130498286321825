import { useEffect } from 'react';
import { FieldValues } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { useStores } from '@shared/hooks';
import { UpdateProfileResponse } from '@shared/queries/application/update-profile/use-update-profile.types';
import { getFormattedDate, scrollToIframe } from '@shared/utils';
import { PublicRoutes } from '@shared/routes';

export const getNextStep = (
  activeStep: number,
  hiddenSteps: Record<string, number>,
  direction: 'back' | 'forward' = 'forward'
): number => {
  const nextStep = direction === 'forward' ? activeStep + 1 : activeStep - 1;
  const hiddenIndex = Object.values(hiddenSteps).findIndex(
    (stepNumber) => stepNumber === nextStep
  );

  if (hiddenIndex !== -1) {
    return getNextStep(nextStep, hiddenSteps, direction);
  }

  return nextStep;
};

export const useHandleNextStep = (updateProfileRefetch: () => Promise<any>) => {
  const navigate = useNavigate();

  const {
    MainStore: {
      applicationStore: {
        activeStep,
        setActiveStep,
        wantNextStep,
        setWantNextStep,
        setPaymenStep,
        hiddenSteps,
        flowConfig: { otpStep, maxSteps, checkDataStep },
      },
      authStore: { setUseOtp, profile, accessToken, setDisableForm },
      productStore: { formState, formString },
    },
  } = useStores();

  useEffect(() => {
    if (wantNextStep && activeStep < (maxSteps || 0)) {
      scrollToIframe();
      if (
        activeStep === otpStep &&
        wantNextStep &&
        shouldUpdateProfile(formState?.WhoIssuesPolicy, profile?.profile)
      ) {
        setDisableForm(true);
        updateProfileRefetch().then((res) => {
          setDisableForm(false);
          if (res?.data) {
            setActiveStep(getNextStep(activeStep, hiddenSteps));
          }
        });
      } else {
        setUseOtp(false);
        setActiveStep(getNextStep(activeStep, hiddenSteps));
      }
    }
    if (wantNextStep && activeStep === maxSteps) {
      setPaymenStep(true);
    }
    setWantNextStep(false);
  }, [
    wantNextStep,
    activeStep,
    accessToken,
    profile,
    formState,
    formString,
    hiddenSteps,
  ]);

  useEffect(() => {
    if (wantNextStep && activeStep === checkDataStep) {
      navigate(PublicRoutes.CHECK_USER_DATA);
    }
  }, [wantNextStep, activeStep]);
};

const shouldUpdateProfile = (
  WhoIssuesPolicy: FieldValues | undefined,
  profile: UpdateProfileResponse['profile'] | undefined
): boolean => {
  if (!WhoIssuesPolicy || !profile) return false;

  if (compareChanges(WhoIssuesPolicy.email, profile.email)) return true;
  if (
    compareChanges(
      getFormattedDate(WhoIssuesPolicy.birthDate),
      profile.birthDate
    )
  )
    return true;
  if (compareChanges(WhoIssuesPolicy.name, profile.firstName)) return true;
  if (compareChanges(WhoIssuesPolicy.secondName, profile.middleName))
    return true;
  if (compareChanges(WhoIssuesPolicy.surname, profile.lastName)) return true;

  return false;
};

const compareChanges = (
  fieldStore: string | undefined,
  fieldService: string | undefined
): boolean =>
  !!fieldStore && String(fieldStore).localeCompare(String(fieldService)) !== 0;
