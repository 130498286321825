import { useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { Endpoints } from '@shared/constants';
import { useRequest, useStores } from '@shared/hooks';
import { PublicRoutes } from '@shared/routes';
import { ErrorCode } from '@shared/constants';

export const useHandleGetSubobjects = () => {
  const {
    MainStore: {
      initProductStore: { setInitState, initState },
      applicationStore: { activeStep },
      authStore: { authTokens },
      productStore: { formState },
    },
  } = useStores();

  const navigate = useNavigate();

  const risks = useMemo(
    () =>
      (formState?.InsuranceRisks?.risks || initState.risks)?.reduce<
        Array<string>
      >((acc, item) => {
        if (item.active) {
          acc.push(item.code);
        }
        return acc;
      }, []),
    [initState.risks]
  );

  const enabled = useMemo(() => {
    return Boolean(risks?.length) && Boolean(initState.code);
  }, [risks?.length, initState.code]);

  const {
    res: data,
    error: subobjectsError,
    refetch,
    isLoading,
  } = useRequest(
    'formIFLGetSubobjects',
    'post',
    Endpoints.GET_SUBOBJECTS,
    {
      productCode: initState.code,
      region: formState.InsuranceAddress?.region,

      // TODO remove hardcode
      risks: risks ? [...risks, 'Liability_1'] : [],
    },
    [initState.code, risks, authTokens?.authorization?.accessToken],
    true,
    authTokens?.authorization?.accessToken
  );

  const errorCode = subobjectsError?.response?.data?.code;

  useEffect(() => {
    if (enabled) {
      refetch();
    }
  }, [enabled, authTokens?.authorization?.accessToken, risks]);

  useEffect(() => {
    if (!isLoading) {
      if (errorCode === ErrorCode.USER_DATA_ERROR) {
        setInitState({});
        navigate(PublicRoutes.POLICY_BANNED);
      }
      if (errorCode === ErrorCode.TECHNICAL_ERROR) {
        navigate(PublicRoutes.ROOT, { state: { activeStep } });
      }
      if (data) {
        setInitState({ ...initState, ...{ subobjects: data } });
      }
    }
  }, [isLoading, data, subobjectsError, activeStep]);

  return { isLoading, data, refetch };
};
