import { WidgetContainer } from '@pulse-web-ui/containers';
import { media, styled } from '@pulse-web-ui/theme';

export const Container = styled(WidgetContainer)`
  display: flex;
  flex-direction: column;
  gap: 16px;

  ${media.desktop} {
    gap: 24px;
  }
`;

export const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(5, 56px);
  gap: 12px;

  ${media.desktop} {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: repeat(4, 56px);
    gap: 16px;

    > div:nth-child(-n + 3) {
      grid-column: span 2;
    }
  }
`;
