import { WidgetContainer } from '@pulse-web-ui/containers';
import { Skeleton } from '@pulse-web-ui/skeleton';

import { useIsDesktop } from '@shared/hooks';

import { OneBlock, TwoBlock } from './skeleton.styles';

export const StartDateSkeleton = () => {
  const isDesktop = useIsDesktop();

  const commonWidth = isDesktop ? 352 : undefined;

  return (
    <WidgetContainer>
      <OneBlock>
        <Skeleton
          width={266}
          height={32}
          borderRadius={100}
          widthMobile={222}
          heightMobile={28}
        />
      </OneBlock>
      <TwoBlock>
        <Skeleton width={commonWidth} height={56} borderRadius={12} />
        <Skeleton width={commonWidth} height={40} borderRadius={100} />
      </TwoBlock>
    </WidgetContainer>
  );
};
