import { DaDataAddressSuggestion } from '@pulse-web-ui/dadata';

export const addressStringExtractor = (e: DaDataAddressSuggestion) => {
  const regions = e?.data?.region_with_type;
  const city = `${
    e?.data && e?.data?.city_with_type ? e?.data?.city_with_type : ''
  }${
    e?.data?.settlement_with_type
      ? e?.data?.city_with_type
        ? ` ${e?.data?.settlement_with_type}`
        : e?.data?.settlement_with_type
      : ''
  }`.trimStart();
  const street = e?.data?.street_with_type || '';
  const house = `${e?.data?.house_type ? e?.data?.house_type : ''}${
    e?.data?.house ? ` ${e?.data?.house}` : ''
  }${e?.data?.block_type ? ` ${e?.data?.block_type}` : ''}${
    e?.data?.block ? ` ${e?.data?.block}` : ''
  }`.trimStart();
  const flat = e?.data?.flat || '';
  return `${regions}, ${city}, ${street}, ${house}, кв ${flat}`;
};
