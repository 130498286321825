import { useEffect } from 'react';

import { useRequest, useStores } from '@shared/hooks';
import { Endpoints, IFrameMessage, QUERY_KEYS } from '@shared/constants';
import { getOrderData } from '@shared/queries';
import { postSubmitMessageToDigitalAgent } from './utils';

export const useHandleAgentSubmit = () => {
  const {
    MainStore: {
      initProductStore: { initState },
      productStore: {
        dadaValueStr,
        cachedDadaValueStr,
        agentLogin,
        formState,
        price: { promoCode },
      },
      authStore: { accessToken },
    },
  } = useStores();

  // TODO: Use common query
  const { isFetching, error, res } = useRequest(
    QUERY_KEYS.submitOrder,
    'post',
    Endpoints.SUBMIT_ORDER,
    getOrderData({ formState, initState, promoCode, dadaValueStr }),
    [cachedDadaValueStr, dadaValueStr],
    true,
    accessToken,
  );

  useEffect(() => {
    if (!isFetching && !!res && !!agentLogin) {
      postSubmitMessageToDigitalAgent(IFrameMessage.SUBSCRIPTION_CREATED);
    }
  }, [res, isFetching]);

  useEffect(() => {
    if (error && !res && !!agentLogin) {
      const e = error?.response?.status;

      if (e === 400 && error?.response?.data?.code === 'USER_DATA_ERROR') {
        postSubmitMessageToDigitalAgent(IFrameMessage.SUBSCRIPTION_ERROR);
      }
    }
  }, [error, res]);

  // TODO: Вернуть refetch сабмита обратно
  return {
    submitOrder: () => {},
    isSubmitLoading: isFetching,
  };
};
