import { analyticEvents } from '@app/web-analytic';
import { useEffect } from 'react';
import { useStores } from '@shared/hooks';

type AnalyticEventValues = (typeof analyticEvents)[keyof typeof analyticEvents];

export const useSendSingleAnalyticEvent = (
  dependence: unknown,
  event: AnalyticEventValues
): ((e?: string | string[]) => void) => {
  const {
    MainStore: {
      applicationStore: { sendSingleAnalyticEvent, clearSendedAnalyticEvent },
    },
  } = useStores();

  useEffect(() => {
    if (dependence) {
      sendSingleAnalyticEvent(event);
    }
  }, [dependence]);

  return clearSendedAnalyticEvent;
};
