/* eslint-disable prettier/prettier */
import { Endpoints } from '@shared/constants';
import { useRequest, useStores } from '@shared/hooks';
import { GetPrice } from '@shared/queries';
import { PublicRoutes } from '@shared/routes';
import { SelectedDuration } from '@shared/types';
import { getActiveCodeRisks } from '@shared/utils';
import React, { useEffect, useMemo, useState } from 'react';
import { TechnicalScreen } from '@pulse-web-ui/technical-screen';
import { baseTheme, StyledThemeProvider } from '@pulse-web-ui/theme';
import { useTranslation } from 'react-i18next';

import { Spinner } from '@pulse-web-ui/spinner';
import { useNavigate } from 'react-router-dom';
import { AvailableSubscription } from './components';
import {
  checkPrices,
  getInsuranceSum,
  subObjectConverter,
  getCheckedSubobjects,
} from './utils';
import type { InsuranceSubObject, InsuranceSubproducts } from './check-user-data.types';

const MAX_REQUEST_COUNT = 8;
const REQUEST_IN_PROGRESS = 'IN_PROGRESS';

export const CheckUserData = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const {
    MainStore: {
      initProductStore: { initState },
      authStore: { authTokens, isScoringSuccess },
      productStore: {
        formState: { InsurancePeriodAndLimits, InsuranceRisks },
        price: { promoCode, prices, defaultContractDuration },
        setPrice,
      },
    },
  } = useStores();

  const [isShowNewTerms, setIsShowNewTerms] =
    useState(false);
  const [insuranceSum, setInsuranceSum] = useState<number>();
  const [checkedSubobjects, setCheckedSubobjects] =
    useState<InsuranceSubObject[]>();
  const [hasAvailableSubscription, setHasAvailableSubscription] =
    useState(false);
  const [readyToNavigate, setReadyToNavigate] = useState(false);

  const period = InsurancePeriodAndLimits?.period as SelectedDuration;
  const selectedDuration = period || defaultContractDuration;
  const risks = InsuranceRisks?.risks || initState?.risks;
  const selectedLimits = InsurancePeriodAndLimits?.limits;
  const isHasLiability = Boolean(selectedLimits?.Liability_1);
  const filteredRisks = useMemo(
    () => getActiveCodeRisks(risks, isHasLiability),
    [risks, getActiveCodeRisks, isHasLiability]
  );
  const { isLoading, res, refetch } = useRequest<InsuranceSubproducts>(
    'getCheckDataSubobjects',
    'post',
    Endpoints.GET_SUBOBJECTS,
    {
      productCode: initState.code,
      // TODO: получать из стора
      region: '0c5b2444-70a0-4932-980c-b4dc0d3f02b5',
      risks: filteredRisks,
    },
    [authTokens?.authorization?.accessToken, risks],
    !isScoringSuccess,
    authTokens?.authorization?.accessToken
  );

  const {
    isLoading: isPricesLoading,
    res: pricesRes,
    refetch: refetchPrices,
  } = useRequest<GetPrice>(
    'getCheckDataPrices',
    'post',
    Endpoints.GET_PRICES,
    {
      productCode: initState.code,
      insuranceSum,
      risks: filteredRisks,
      returnMinDuration: false,
      personProperties: {
        subObjects: checkedSubobjects && subObjectConverter(checkedSubobjects),
        // TODO: получать из стора
        address: {
          regionCode: '0c5b2444-70a0-4932-980c-b4dc0d3f02b5',
          address: 'г Москва, ул Цандера, д 10, кв 1',
          addressCode: 'cfa23745-efe0-4083-b6ed-bafc0848405a',
        },
      },
      promoCode,
    },
    [risks, insuranceSum, checkedSubobjects, promoCode],
    true,
    authTokens?.authorization?.accessToken
  );

  let timeout: NodeJS.Timeout;
  let requestCount = 1;

  const refetchSubobjects = (status: string | undefined) => {
    if (status === REQUEST_IN_PROGRESS && requestCount < MAX_REQUEST_COUNT) {
      clearTimeout(timeout);
      requestCount = requestCount + 1;

      timeout = setTimeout(() => {
        refetch().then((res) => {
          refetchSubobjects(res?.data?.data.applicationStatus);
        });
      }, 1000);
    } else {
      clearTimeout(timeout);

      const oldSubobjects = initState?.subobjects
        ?.subObjects as unknown as InsuranceSubObject[];
      if (res?.subObjects && oldSubobjects && selectedLimits) {
        const { hasChanged, checkedSubobjects } = getCheckedSubobjects(
          oldSubobjects,
          res.subObjects,
          selectedLimits
        );
        const changedInsuranceSum = getInsuranceSum(checkedSubobjects);

        setHasAvailableSubscription(hasChanged);
        setCheckedSubobjects(checkedSubobjects);
        setInsuranceSum(changedInsuranceSum);
      }
    }
  };

  useEffect(() => {
    if (!isLoading) {
      refetchSubobjects(res?.applicationStatus);
    }
  }, [isLoading, res]);

  useEffect(() => {
    if (checkedSubobjects && insuranceSum) {
      refetchPrices();
    }
  }, [checkedSubobjects, insuranceSum]);

  useEffect(() => {
    if (readyToNavigate) {
      if (hasAvailableSubscription) {
        setIsShowNewTerms(true);
      } else {
        navigate(PublicRoutes.ROOT);
      }
    }
  }, [hasAvailableSubscription, readyToNavigate]);

  useEffect(() => {
    if (!isPricesLoading && pricesRes) {
      const oldPrice = prices?.find(
        (price) => price.duration === selectedDuration
      );
      const newPrice = pricesRes?.prices?.find(
        (price) => price.duration === selectedDuration
      );

      if (oldPrice && newPrice) {
        const hasChanged = !checkPrices(oldPrice, newPrice, !!promoCode);

        setHasAvailableSubscription(hasChanged);
        setReadyToNavigate(true);
      }

      setPrice(pricesRes);
    }
  }, [isPricesLoading, pricesRes]);

  const [insuranceSumPerMonth, insurancePromoSumPerMonth] = useMemo(() => {
    const price = pricesRes?.prices?.find(
      (price) => price.duration === selectedDuration
    );
    return [
      Number(price?.premiumAndDelta),
      Number(price?.premiumAndDeltaPromo),
    ];
  }, [pricesRes?.prices]);

  return (
    <StyledThemeProvider theme={baseTheme}>
      {isShowNewTerms ? (
        <AvailableSubscription
          risks={risks}
          period={period}
          subobjects={checkedSubobjects}
          insuranceSum={insuranceSum}
          insuranceSumPerMonth={insuranceSumPerMonth}
          insurancePromoSumPerMonth={insurancePromoSumPerMonth}
        />
      ) : (
        <TechnicalScreen
          icon={<Spinner size={40} />}
          title={t('COMMON:headers.checkYourData')}
          subtitle={t('COMMON:hints.usuallyItTakes')}
          isShow={true}
          isFullView
        />
      )}
    </StyledThemeProvider>
  );
};
