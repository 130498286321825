import { observer } from 'mobx-react-lite';
import { Suspense, useCallback, useMemo } from 'react';

import { FallbackSkeleton } from '@shared/components';
import { useStores } from '@shared/hooks';

import { SmartHeader } from '@entities/import-smart-components/smart-header';

import { getNextStep } from '@features/bff-box/hooks/use-handle-next-step';

export const HeaderAdapter = observer(() => {
  const {
    MainStore: {
      applicationStore: {
        activeStep: storeActiveStep,
        setActiveStep,
        deviceType,
        phoneFromMobileApp,
        flowConfig: { headerConfig, isShowBackButton },
        hiddenSteps,
      },
      authStore: { setDisableForm },
    },
  } = useStores();

  const isBackButtonVisible = !!(storeActiveStep > 1 && isShowBackButton);

  const { config, activeStep } = useMemo(() => {
    /* В сторе (в flowConfig.headerConfig) хранятся все шаги, без учета скрытых
    Для корректной отрисовки навигации необходимо:
    - отфильтровать элементы готовые к показу
    - скорректировать номер активного шага, на основе показываемых шагов */

    const config = headerConfig?.filter((item) => !hiddenSteps[item.name]);
    const activeStep =
      (config?.findIndex(
        (step) => step.name === headerConfig?.[storeActiveStep - 1].name
      ) || 0) + 1;

    return {
      config,
      activeStep: activeStep || 1,
    };
  }, [headerConfig, hiddenSteps, storeActiveStep]);

  const handleStepChange = useCallback((step: number) => {
    setDisableForm(false);
    setActiveStep(step + 1);
  }, []);

  const handleOnClickBack = useCallback(() => {
    setDisableForm(false);
    setActiveStep(getNextStep(storeActiveStep, hiddenSteps, 'back'));
  }, [storeActiveStep, hiddenSteps]);

  return (
    <Suspense fallback={<FallbackSkeleton height={0} transparent />}>
      <SmartHeader
        config={config || []}
        activeStep={activeStep}
        handleStepChange={handleStepChange}
        handleOnClickBack={handleOnClickBack}
        isShowBackButton={isBackButtonVisible}
        isMobileApp={Boolean(phoneFromMobileApp && deviceType)}
      />
    </Suspense>
  );
});

HeaderAdapter.displayName = 'HeaderAdapter';
