import { observer } from 'mobx-react-lite';
import { Suspense, forwardRef, useEffect } from 'react';
import type { FC, Ref } from 'react';
import type { SmartComponentAdapterProps } from 'smart-components';

import { InsuranceRisksValues } from '@pulse-smart-components-kit/insurance-risks';

import { useStores } from '@shared/hooks';

import { SmartInsuranceRisks } from '@entities/import-smart-components/smart-insurance-risks';

import { useHandleGetRisks } from './hooks';
import { getOptionsValue } from './insurance-risks-helpers';
import { formInsuranceRisksSchema } from './schema';
import { InsuranceRisksSkeleton } from './skeleton';

export const InsuranceRisksAdapter: FC<
  SmartComponentAdapterProps<InsuranceRisksValues>
> = observer(
  forwardRef(
    (
      { isSubmitting, fieldState, value, onChange },
      forwardRef: Ref<HTMLDivElement>
    ) => {
      const {
        MainStore: {
          authStore: { disableForm },
        },
      } = useStores();
      const { data } = useHandleGetRisks();

      useEffect(() => {
        if (!data || !data.length) return;

        onChange(getOptionsValue(data, value));
      }, [data]);

      return (
        <Suspense fallback={<InsuranceRisksSkeleton />}>
          <SmartInsuranceRisks
            onChange={onChange}
            value={value}
            ref={forwardRef}
            disabled={disableForm}
            fieldState={fieldState}
            formSchema={formInsuranceRisksSchema}
            isSubmitting={isSubmitting}
            content={{}}
            onAnalyticEventSend={{}}
            formContext={{}}
            options={{}}
          />
        </Suspense>
      );
    }
  )
);
